import { FeatureFlags } from 'legacy/app/enums/featureFlags/featureFlags';
import URI from 'legacy/defaults/RoutesDefault';
import { useHeader } from 'legacy/hooks/useHeader';
import React from 'react';
import { Button } from 'react-bootstrap';
import { useFeature } from 'use-feature';

export const CreditCardOverviewHeader = () => {
	const showFeature = useFeature(FeatureFlags.ReconcileCC);

	useHeader({
		title: 'Credit Card',
		breadcrumbs: [
			{
				text: 'Accounts Payable',
				to: URI.accountsPayable.creditCard.base,
			},
			{
				text: 'Credit Card',
			},
		],
		actions: showFeature
			? [
					<Button
						key="btn-reconcile-cta"
						variant="secondary"
						href={URI.accountsPayable.creditCard.reconcile}
					>
						Reconcile
					</Button>,
			  ]
			: [],
		enableBackButton: false,
	});
	return <></>;
};

CreditCardOverviewHeader.displayName = 'CreditCardOverviewHeader';
