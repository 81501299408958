import useGetFile from 'legacy/lib/api/hooks/useGetFile';
import React from 'react';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import MyDropzone from 'legacy/templates/ui-kit/MyDropzone';
import { Component } from 'legacy/lib/api/types/Component';

const ImageUploader = ({ component }: { component: Component }) => {
	const methods = useFormContext();
	const { setValue } = methods;

	const { data: editItemImage, isFetching } = useGetFile(
		component?.primaryImageId as string,
		{
			enabled: !!component?.primaryImageId,
		}
	);

	useEffect(() => {
		if (editItemImage && !isFetching) {
			setValue('image', editItemImage);
		}
	}, [editItemImage, setValue, isFetching]);

	return (
		<div className="flex-1 lg:flex-[0.5]">
			<Controller
				control={methods.control}
				name="image"
				render={({ field }) => (
					<MyDropzone
						doNotShowConfirmationModal
						setImageOnEveryChange
						image={field.value}
						onRemoveFiles={field.onChange}
						onAcceptedFiles={field.onChange}
					/>
				)}
			/>
		</div>
	);
};

ImageUploader.displayName = 'ImageUploader';

export default ImageUploader;
