import React from 'react';
import { Row, Col, Modal, Form, Button } from 'react-bootstrap';
import { ApiService } from '../../../lib/api/HttpService';
import {
	displayAlertError,
	displayAlertLoader,
	displayAlertSuccess,
	getErrorMessage,
} from '../../../utilities/Response';
import ThreadComponent from '../ThreadComponent';
import { plural, removeSpaces } from '../../../utilities/String';
import MSG from '../../../defaults/Message';
import { isEmail } from '../../../utilities/Validation';
import { isEmpty } from 'lodash';

class SendPurchaseOrderModal extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			hasFile: false,
			emailAttributes: {
				to: '',
				cc: '',
				bcc: '',
				subject: '',
				message: '',
				attachment: [],
				includeUserSignature: true,
				objects: [],
			},
			company: {
				name: '',
			},
			data: [],
		};

		this.api = new ApiService();
	}

	componentDidMount = async () => {
		const companyDetails = await this.api.getCompany();

		this.setState({ company: companyDetails });

		const data = this.props.sendPOData;
		if (data.length) {
			const vendorName = data[0].vendorName;
			const address = await this.api.getAddresses(
				`?$filter=addresstype eq 2 and code eq '${encodeURIComponent(
					data[0].supplier
				)}'`
			);

			const objects = data.map((i) => {
				return {
					objectId: i.id,
					objectType: 12,
				};
			});

			const attachedFiles = objects
				.filter((i) => i.fileId)
				.map((i) => i.fileId);

			const pdfs = data.filter((i) => i.fileId).map((i) => i.fileId);

			const files = attachedFiles.concat(pdfs);

			this.setState({
				hasFile: data.length ? (data[0].fileId ? true : false) : false,
				emailAttributes: {
					...this.state.emailAttributes,
					to: address[0]?.email,
					subject: `Purchase/Work Order(s) from ${this.state.company.name}`,
					message: `Dear ${vendorName},\n\n${this.state.company.name} has sent the attached purchase orders.`,
					objects,
					files,
				},
				data,
			});
		}
	};

	onFileChange = (e) => {
		this.setState((prev) => (prev.hasFile = e.target.value ? true : false));
		this.setState((prevState) => ({
			emailAttributes: {
				...prevState.emailAttributes,
				attachment: e.target.files,
			},
		}));
	};

	onChange = (e) => {
		this.setState((prevState) => ({
			emailAttributes: {
				...prevState.emailAttributes,
				[e.target.name]: e.target.value,
			},
		}));
	};

	attachPdf() {
		const { data } = this.state;
		if (data.length && data[0].fileId) {
			return <>Purchase Order PDFs are automatically attached.</>;
		} else {
			return '';
		}
	}

	handleChecked = (e) => {
		this.setState((prevState) => ({
			emailAttributes: {
				...prevState.emailAttributes,
				[e.target.name]: e.target.checked,
			},
		}));
	};

	renderMessage = () => {
		return this.state.emailAttributes.message
			? this.state.emailAttributes.message
			: `Dear ${this.props.sendPOData[0]?.vendorName},\n\n${this.state.company.name} has sent the attached purchase orders.`;
	};

	splitEmails = (emails) => {
		if (emails && typeof emails === 'string') {
			return emails.split(',').map((email) => email.trim());
		}
		return [];
	};

	validate() {
		const { to, cc, bcc } = this.state.emailAttributes;

		const toList = this.splitEmails(to);
		const ccList = this.splitEmails(cc);
		const bccList = this.splitEmails(bcc);

		const invalidTo = toList?.filter((em) => isEmail(em) === false);
		const invalidCc = ccList?.filter((em) => isEmail(em) === false);
		const invalidBcc = bccList?.filter((em) => isEmail(em) === false);

		const { emailAttributes } = this.state;
		if (isEmpty(removeSpaces(emailAttributes.to))) {
			displayAlertError('Please enter a valid email address');
			return false;
		}

		if (invalidTo.length) {
			displayAlertError(
				`Please enter a valid email address for [to]: {${invalidTo.join(
					', '
				)}}.`
			);
			return false;
		}

		if (ccList.length && invalidCc.length) {
			displayAlertError(
				`Please enter a valid email address for [cc]: {${invalidCc.join(
					', '
				)}}.`
			);
			return false;
		}

		if (bccList.length && invalidBcc.length) {
			displayAlertError(
				`Please enter a valid email address for [bcc]: {${invalidBcc.join(
					', '
				)}}.`
			);
			return false;
		}

		return true;
	}

	onSend = async () => {
		const totalPOs = this.state.data?.length;

		if (!this.validate()) {
			return;
		}

		displayAlertLoader(
			plural(MSG.loading.email.PO, 'purchase order', totalPOs)
		);
		const files = this.state.emailAttributes?.files ?? [];
		if (this.state.emailAttributes?.attachment.length) {
			const uploadedFile = await this.api.saveFile({
				file: this.state.emailAttributes?.attachment[0],
				FileType: 'other',
			});

			files.push(uploadedFile.id);
		}

		const data = this.props.sendPOData;
		const objects = data.map((i) => {
			return {
				objectId: i.id,
				objectType: 12,
			};
		});

		const notificationData = {
			notificationTypeId: 1,
			emailData: {
				to: this.splitEmails(this.state.emailAttributes?.to),
				cc: this.splitEmails(this.state.emailAttributes?.cc),
				bcc: this.splitEmails(this.state.emailAttributes?.bcc),
				subject: this.state.emailAttributes?.subject,
				message: this.state.emailAttributes?.message,
			},
			objects,
			includeUserSignature: this.state.emailAttributes?.includeSignature,
			...(files.length && { files }),
		};
		await this.api
			.postNotification(notificationData)
			.then((response) => {
				if (response.isOk) {
					displayAlertSuccess(
						plural(MSG.success.email.PO, 'purchase order', totalPOs)
					);
					setTimeout(() => {
						this.props.hideModal();
					}, 500);
				}
			})
			.catch((error) => {
				displayAlertError(getErrorMessage(error));
			});
	};

	getValidEmails(values) {
		if (!isEmpty(values)) {
			values = values
				.replace(/\s/gi, ',')
				.replace(/\//g, ',')
				.replace(/\|/g, ',');
			values = values.split(',');
			values = values
				.map((email) => {
					return removeSpaces(email);
				})
				.filter((email) => {
					if (isEmail(removeSpaces(email))) {
						return true;
					} else {
						!isEmpty(email) &&
							displayAlertError(
								`<u>${email}</u>&nbsp;is not a valid email address`
							);
						return false;
					}
				});

			return values.join(',');
		}

		return '';
	}

	resetFields() {
		const emailAttributes = this.state.emailAttributes;
		emailAttributes.to = '';
		emailAttributes.cc = '';
		emailAttributes.bcc = '';
		this.setState({
			emailAttributes,
		});
	}

	render() {
		return (
			<Modal
				size="lg"
				show={true}
				onHide={() => {
					this.props.hideModal();
				}}
				aria-labelledby="example-modal-sizes-title-lg"
				className="right a-modal fixed-footer"
				backdrop="static"
			>
				<Modal.Body>
					<div className="p-5 mt-5 mx-auto" style={{ maxWidth: '600px' }}>
						<h4 className="ff-type-medium">Send Purchase Order Email</h4>
						<div className="form-fields px-4 mt-5">
							<Form.Group
								as={Row}
								className="mb-3"
								controlId="formPlaintextEmail"
							>
								<Form.Label
									column
									sm="2"
									className="fw-700 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
								>
									To:
								</Form.Label>
								<Col sm="10">
									<Form.Control
										type="text"
										placeholder=""
										size="sm"
										defaultValue={
											this.state.emailAttributes.to ||
											this.props.sendPOdata?.address[0]?.email
										}
										name="to"
										onChange={this.onChange}
										onBlur={(e) => {
											e.target.value = this.getValidEmails(e.target.value);
											this.onChange(e);
										}}
									/>
								</Col>
							</Form.Group>
							<Form.Group
								as={Row}
								className="mb-3"
								controlId="formPlaintextEmail"
							>
								<Form.Label
									column
									sm="2"
									className="fw-700 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
								>
									Cc:
								</Form.Label>
								<Col sm="10">
									<Form.Control
										type="text"
										placeholder=""
										size="sm"
										defaultValue={this.state.emailAttributes.cc}
										name="cc"
										onChange={this.onChange}
										onBlur={(e) => {
											e.target.value = this.getValidEmails(e.target.value);
											this.onChange(e);
										}}
									/>
								</Col>
							</Form.Group>
							<Form.Group
								as={Row}
								className="mb-4 pb-2"
								controlId="formPlaintextEmail"
							>
								<Form.Label
									column
									sm="2"
									className="fw-700 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
								>
									Bcc:
								</Form.Label>
								<Col sm="10">
									<Form.Control
										type="text"
										placeholder=""
										defaultValue={this.state.emailAttributes.bcc}
										name="bcc"
										onChange={this.onChange}
										onBlur={(e) => {
											e.target.value = this.getValidEmails(e.target.value);
											this.onChange(e);
										}}
									/>
								</Col>
							</Form.Group>
							<Form.Group
								as={Row}
								className="mb-3"
								controlId="formPlaintextEmail"
							>
								<Form.Label
									column
									sm="2"
									className="fw-700 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
								>
									Subject:
								</Form.Label>
								<Col sm="10">
									<Form.Control
										type="text"
										placeholder=""
										size="sm"
										defaultValue={
											this.state.emailAttributes.subject ||
											`Purchase/Work Order(s) from ${this.state.company.name}`
										}
										name="subject"
										onChange={this.onChange}
									/>
								</Col>
								{this.state.data.length && (
									<Col sm="10" className="mt-3 offset-sm-2">
										<ul className="m-0 p-0">
											{this.state.data.map((o) => (
												<li key={o.id}>Orders for {o.purchaseOrderName}</li>
											))}
										</ul>
									</Col>
								)}
							</Form.Group>

							<Form.Group
								as={Row}
								className="mb-3"
								controlId="formPlaintextEmail"
							>
								<Form.Label
									column
									sm="2"
									className="fw-700 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
								>
									Attachment:
								</Form.Label>
								<Col sm="10">
									<div className="form-group-extra">
										<Form.Control
											type="file"
											placeholder=""
											title=""
											size="sm"
											className={`file-upload-white ${
												this.state.hasFile ? 'has-file' : ''
											}`}
											onChange={this.onFileChange}
										/>
										<span className="text-secondary-ash ri-attachment-line"></span>
									</div>
								</Col>
								<Col sm="2" />
								<Col sm="10" className="mt-3">
									{this.attachPdf()}
								</Col>
							</Form.Group>
							<Form.Group
								as={Row}
								className="mb-3"
								controlId="formPlaintextEmail"
							>
								<Form.Label
									column
									sm="2"
									className="fw-700 d-flex justify-content-sm-end align-items-start px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
								>
									Message:
								</Form.Label>
								<Col sm="10">
									<Form.Control
										as="textarea"
										rows={10}
										placeholder=""
										size="sm"
										defaultValue={
											this.state.emailAttributes.message || this.renderMessage()
										}
										name="message"
										onChange={this.onChange}
									/>
								</Col>
							</Form.Group>
							<Form.Group
								as={Row}
								className="mb-3"
								controlId="formPlaintextEmail"
							>
								<Form.Label
									column
									sm="2"
									className="fw-700 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
								></Form.Label>
								<Col sm="10">
									<Form.Check
										label="Include Signature from User Settings"
										name="includeUserSignature"
										onChange={this.handleChecked}
										defaultChecked={
											this.state.emailAttributes.includeUserSignature
										}
										id={`inline-md-radio-4`}
									/>
								</Col>
							</Form.Group>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer className="bg-ivory py-4 px-5">
					<Row className="w-100">
						<Col lg={{ span: 10, offset: 1 }}>
							<Button
								variant="primary"
								onClick={this.onSend}
								className="w-100 mb-2"
							>
								Send
							</Button>
							<Button
								variant="trans-light border-secondary-ash mt-1"
								className="w-100"
								onClick={() => {
									this.resetFields();
									this.props.hideModal();
								}}
							>
								Cancel
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default SendPurchaseOrderModal;
