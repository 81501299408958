import { useQuery } from '@tanstack/react-query';
import { ApiService } from '../HttpService';
import { IItemImages } from '../types/ItemImages';

const api = new ApiService();

const useGetItemImages = (filter = '') => {
	return useQuery<IItemImages[], Error>(
		['itemImages', filter],
		() => api.getItemImages(filter),
		{
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
		}
	);
};

export default useGetItemImages;
