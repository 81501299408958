import React, { useState, useEffect, FocusEvent } from 'react';
import Select from 'react-select';
import useGetRecentProjects from 'legacy/lib/api/hooks/useGetRecentProjects';
import { TRecentProject } from 'legacy/lib/api/types/RecentProject';

interface IOption {
	value: string;
	label: string;
}

interface IRecentProjectsSelect {
	handleSelect: (project: TRecentProject | null) => void;
}

const RecentProjectsSelect = ({ handleSelect }: IRecentProjectsSelect) => {
	const defaultFilter = '?$orderby=dateOpened desc';
	const [filter, setFilter] = useState(defaultFilter);
	const { projects, updateFilter } = useGetRecentProjects(filter);
	const [selectedOption, setSelectedOption] = useState<IOption | null>(null);
	const [isDisabled, setIsDisabled] = useState<boolean>(true);

	useEffect(() => {
		if (projects?.length > 0 && !selectedOption) {
			setIsDisabled(false);
			const firstProject = projects[0];
			handleSelect(firstProject);
			setSelectedOption({
				value: firstProject.proj,
				label: `${firstProject.projectName} [${firstProject.proj}]`,
			});
		}
	}, [projects, selectedOption, handleSelect]);

	const handleInputChange = (
		inputValue: FocusEvent<HTMLInputElement, Element>
	) => {
		const value = inputValue?.target?.value || '';
		if (value === '') {
			updateFilters(defaultFilter);
		} else {
			const newFilter = `?$filter=contains(projectName,'${value}') or contains(proj,'${value}')`;
			updateFilters(newFilter);
		}
	};

	const options = projects?.map((project: TRecentProject) => ({
		value: project.proj,
		label: `${project.projectName} [${project.proj}]`,
	}));

	const handleOptionSelect = (option: IOption | null) => {
		const selectedProject = projects?.find(
			(project: TRecentProject) => project.proj === option?.value
		);
		if (selectedProject) {
			handleSelect(selectedProject);
			setSelectedOption({
				value: selectedProject.proj,
				label: `${selectedProject.projectName} [${selectedProject.proj}]`,
			});
		}
	};

	const updateFilters = (filter: string) => {
		setFilter(filter);
		updateFilter(filter);
	};

	return (
		<Select
			options={options}
			blurInputOnSelect
			onBlur={handleInputChange}
			placeholder="Select Recent project"
			onChange={(option) => handleOptionSelect(option as IOption | null)}
			value={selectedOption}
			isDisabled={isDisabled}
			onMenuClose={() => updateFilters(defaultFilter)}
		/>
	);
};

RecentProjectsSelect.displayName = 'RecentProjectsSelect';

export default RecentProjectsSelect;
