import React, { useState, useEffect, useMemo, useRef } from 'react';
import useGetFile from 'legacy/lib/api/hooks/useGetFile';
import useGetItemImages from 'legacy/lib/api/hooks/useGetItemImages';
import useGetFiles from 'legacy/lib/api/hooks/useGetFiles';
import ImageDragAndDrop from 'legacy/app/components/ImageDragAndDrop/ImageDragAndDrop';
import { TDisplayImage, TItemImage } from 'legacy/lib/api/types/ItemImages';
import { ImageGalleryThumbnails } from './ImageGalleryThumbnails';

interface IImageGalleryProps {
	handleImages: (images: TItemImage[]) => void;
	itemId: number;
	itemPrimaryImageId: string;
}

export const ImageGallery = ({
	handleImages,
	itemId,
	itemPrimaryImageId,
}: IImageGalleryProps) => {
	const [selectedImage, setSelectedImage] = useState<TDisplayImage>({
		file: undefined,
		index: 0,
	});
	const [allImages, setAllImages] = useState<TItemImage[]>();
	const filter = `?$filter=ObjectIdStr eq '${itemId}' and objectType eq 'Item' &$orderby=order`;
	const { data: initialImagesData } = useGetItemImages(filter);
	const imagesIds = initialImagesData?.map((item) => item.fileId) || [];

	const { queries: initialImagesQuery, isFetching: isFetchingQueryImages } =
		useGetFiles(imagesIds);

	const { data: editItemImage } = useGetFile(itemPrimaryImageId as string, {
		enabled: !!(itemPrimaryImageId && itemPrimaryImageId),
	});

	const initialItemImages = useMemo(() => {
		return initialImagesQuery
			?.map((query) => ({
				file: query.data?.file,
				fileId: query.data?.fileId,
			}))
			?.filter((image) => image.file && image.fileId);
	}, [initialImagesQuery]);

	useEffect(() => {
		if (editItemImage) {
			setSelectedImage({ file: editItemImage, index: 0 });
		}
	}, [editItemImage]);

	const handleDrop = (file: File) => {
		const images = allImages || [];
		const updatedImages = [...images];
		if (selectedImage.index !== null) {
			if (allImages && selectedImage.index >= allImages?.length) {
				updatedImages?.push({ file, fileId: null });
			} else {
				updatedImages[selectedImage.index] = { file, fileId: null };
			}
			setAllImages(updatedImages);
			handleImages(updatedImages);
			setSelectedImage({ file, index: selectedImage.index });
		}
	};

	const handleDeleteImage = (index: number) => {
		const images = allImages || [];
		const updatedImages = [...images];
		updatedImages.splice(index, 1);
		setAllImages(updatedImages);
		handleImages(updatedImages);
		setSelectedImage({ file: updatedImages[0]?.file || undefined, index: 0 });
	};

	const handleGalleryImages = (images: TItemImage[]) => {
		handleImages(images);
		setAllImages(images);
	};

	const imagesSetRef = useRef(false);

	useEffect(() => {
		if (
			!isFetchingQueryImages &&
			initialItemImages?.length > 0 &&
			!imagesSetRef.current
		) {
			setAllImages(
				initialItemImages.map((image) => ({
					file: image.file || null,
					fileId: image.fileId || null,
				})) as TItemImage[]
			);

			imagesSetRef.current = true;
		}
	}, [isFetchingQueryImages, initialItemImages, setAllImages]);

	return (
		<div className="tw-flex-1 tw-h-full lg:tw-flex-[0.6] xl:tw-flex[0.5]">
			<div className="tw-flex tw-flex-col tw-justify-between tw-h-full">
				<ImageDragAndDrop
					image={selectedImage?.file || null}
					title="Add Photo"
					subtitle="or Drag and Drop"
					iconClass="tw-text-5xl tw-font-normal"
					handleFile={(file) => handleDrop(file)}
					handleDelete={handleDeleteImage}
					imageIndex={selectedImage?.index || 0}
				/>
				<ImageGalleryThumbnails
					images={allImages as TItemImage[]}
					setSelectedImage={setSelectedImage}
					selectedImageIndex={selectedImage?.index || 0}
					handleImages={handleGalleryImages}
				/>
			</div>
		</div>
	);
};

ImageGallery.displayName = 'ImageGallery';
