import { useQuery } from '@tanstack/react-query';
import { ApiService } from '../HttpService';

const api = new ApiService();

interface IGetCompanyReturn {
	whshipto: string;
	fiscaloffset: number;
}

const useGetCompany = (filter = '') => {
	return useQuery<IGetCompanyReturn, Error>(
		['company', filter],
		() => api.getCompany(filter),
		{
			refetchOnWindowFocus: false,
		}
	);
};

export default useGetCompany;
