import React, { useState, useEffect, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Row, Form, Container, Breadcrumb, Button } from 'react-bootstrap';
import Select from 'react-select';
import { useParams, Link, NavLink } from 'react-router-dom';
import { HeaderLight } from '../../../components/Header';
import { FooterFormAction } from '../../../components/Form';
import URI from '../../../../defaults/RoutesDefault';
import { ApiService } from '../../../../lib/api/HttpService';
import { displayAlert } from '../../../../utilities/Response';
import UserRoleDropdown from '../../../../app/components/dropdowns/UserRoleDropdown';
import { UserEditValidation } from './validations/UserEditValidation';
import { validateInput } from '../../../../app/components/validators/Helpers';
import { getAllInstances, getClientId } from '../../../../helpers/Instances';
import { generatePath } from 'react-router-dom';
import Spinner from 'legacy/app/components/help/Spinner';
import RoleTooltip from './RoleTooltip/RoleTooltip';
import { AuthorizationTemplateType } from 'legacy/app/enums/settings/userSettings';

const Header = () => {
	return (
		<HeaderLight>
			<HeaderLight.Breadcumbs>
				<NavLink
					to={URI.settings.userManagement.list}
					className="text-primary active d-flex align-items-center text-decoration-none fw-bold"
				>
					<i className="ri-arrow-left-s-line"></i> Back
				</NavLink>
				<Breadcrumb className="ms-4">
					<Breadcrumb.Item
						linkProps={{ to: URI.settings.userManagement.list }}
						linkAs={Link}
					>
						User Management
					</Breadcrumb.Item>
					<Breadcrumb.Item>Edit User</Breadcrumb.Item>
				</Breadcrumb>
			</HeaderLight.Breadcumbs>
			<HeaderLight.Content>
				<HeaderLight.Title>Edit User</HeaderLight.Title>
			</HeaderLight.Content>
		</HeaderLight>
	);
};

const Content = () => {
	const [employees, setEmployees] = useState([]);
	const [user, setUser] = useState({
		disabled: false,
		isAdmin: false,
		username: '',
		useremail: '',
		accountEmail: '',
		usertitle: '',
		password: '',
		emp: '',
	});
	const [userId, setUserId] = useState(null);
	const [clientCompanyId, setClientCompanyId] = useState(null);
	const [allInstances, setAllInstances] = useState([]);
	const [userInstances, setUserInstances] = useState([]);
	const [instances, setInstances] = useState({});
	const [uneditedInstances, setUneditedInstances] = useState([]);
	const [userEditId, setUserEditId] = useState();

	const [userRole, setUserRole] = useState(user?.attributes);

	const [loading, setLoading] = useState(false);

	const {
		register,
		handleSubmit,
		setValue,
		watch,
		formState: { errors },
		control,
	} = useForm();
	const api = new ApiService();
	const params = useParams();

	useEffect(() => {
		getClientId(setClientCompanyId);
	}, []);

	useEffect(() => {
		clientCompanyId && getAllInstances(clientCompanyId, setAllInstances);
		const instancesList = allInstances?.map((instance) => ({
			InstanceId: instance.instanceId,
			Enabled: userInstances.some(
				(userInstance) => userInstance.instanceId === instance.instanceId
			),
		}));
		setInstances(instancesList);
		setUneditedInstances(instancesList);
	}, [clientCompanyId, userInstances]);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);

			try {
				const username = params.id;
				let userData = await api.getUserData(
					`?$filter=password eq '${username}'`
				);

				userData = userData[0] || {};

				let employeesData = await api.get('employees');
				setEmployees(
					employeesData?.map((emp) => ({
						value: emp.vendor,
						label: `[${emp.vendor}] ${emp.vendorn}`,
					}))
				);

				let usersData = await api.get('manageaccount');

				const accountEmail = usersData.users.find(
					(u) => u.username === username
				);
				userData.accountEmail = accountEmail.email;
				userData.disabled = accountEmail.disabled;
				userData.isAdmin = accountEmail.isAdmin;

				if (!userData.hasOwnProperty('password')) {
					userData.password = accountEmail.username;
				}

				if (!userData.hasOwnProperty('useremail')) {
					userData.useremail = accountEmail.email;
				}
				setUser(userData);
				setUserId(accountEmail.userId);
			} catch (error) {
				displayAlert('danger', 'Failed to load user data');
			} finally {
				setLoading(false);
			}
		};
		fetchData();
	}, [params.id]);

	useEffect(() => {
		const fetchData = async () => {
			const { instances } = (await api.getUserInstances(user?.password)) || [];

			const userId = instances.length > 0 && instances[0].userId;
			userId && setUserEditId(userId);

			instances && setUserInstances(instances);
		};

		user?.password && !loading && fetchData();
	}, [user?.password, loading]);

	useEffect(() => {
		if (user) {
			setValue('username', user.username);
			setValue('useremail', user.useremail);
			setValue('accountEmail', user.accountEmail);
			setValue('usertitle', user.usertitle);
			setValue('isAdmin', user.isAdmin);
			setValue('disabled', user.disabled);
			setValue('password', user.password);
			setValue('emp', user.emp);
			handleSetUserRole(user);
		}
	}, [user, setValue]);

	const handleSetUserRole = (user) => {
		const { authorizationTemplateType, attributes } = user;
		if (authorizationTemplateType === AuthorizationTemplateType.Custom) {
			setUserRole(AuthorizationTemplateType.Custom);
		} else {
			setUserRole(attributes);
		}
	};

	const handleRoleChange = (selectedRole) => {
		setValue('authorizationTemplateType', selectedRole.value);
		setValue('attributes', selectedRole.attribute);
		setUserRole(selectedRole.attribute);
	};

	const onSubmit = async (formData) => {
		const completeData = {
			...user,
			...formData,
		};

		const updatedInstances = {
			Instances: instances.filter((instance) => {
				const matchingUserInstance = uneditedInstances.find(
					(userInstance) => userInstance.InstanceId === instance.InstanceId
				);

				return (
					matchingUserInstance &&
					instance.Enabled !== matchingUserInstance.Enabled
				);
			}),
		};

		const updateInstances = updatedInstances.Instances?.length > 0;

		const validated = await validateInput(UserEditValidation, completeData);

		if (!validated.status) {
			let isSuccessfulUpdate = true;
			const userStatus = {
				userid: userId,
				disabled: completeData.disabled,
			};

			if (completeData.disabled !== user.disabled) {
				await api.editUserStatus(userStatus).catch((err) => {
					isSuccessfulUpdate = false;
					const errorMessage =
						err.response?.data?.userError || 'User status could not be updated';
					displayAlert('danger', errorMessage);
				});
			}

			const manageAccountData = {
				email: completeData.accountEmail,
				isAdmin: completeData.isAdmin,
			};
			await api.editManageAccount(userId, manageAccountData).catch((err) => {
				isSuccessfulUpdate = false;
				const errorMessage =
					err.response?.data?.userError ||
					'User account management email could not be updated';
				displayAlert('danger', errorMessage);
			});

			updateInstances &&
				(await api
					.editUserInstances(userEditId, updatedInstances)
					.catch((err) => {
						isSuccessfulUpdate = false;
						const errorMessage =
							err.response?.data?.userError ||
							'User Instances could not be updated';
						displayAlert('danger', errorMessage);
					}));

			if (completeData.usercode) {
				await api
					.editUserData(completeData.usercode, completeData)
					.then((resp) => {
						isSuccessfulUpdate = true;
						displayAlert('success', 'User has been updated successfully');
						setTimeout(() => {
							window.onbeforeunload = null;
							window.location.href = generatePath(
								URI.settings.userManagement.list
							);
						}, 1500);
					})
					.catch((err) => {
						displayAlert('danger', 'User could not be updated');
					});
			}

			if (isSuccessfulUpdate && !completeData.usercode) {
				// This case is when user doesn't have data in useraccounts endpoint
				displayAlert('success', 'User has been updated successfully');
				setTimeout(() => {
					window.onbeforeunload = null;
					window.location.href = generatePath(URI.settings.userManagement.list);
				}, 1500);
			}
		} else {
			displayAlert('danger', 'Please enter a valid email');
		}
	};

	const handleInstanceChange = (instanceId, isChecked) => {
		const updatedInstances = instances.map((d) =>
			d.InstanceId === instanceId ? { ...d, Enabled: isChecked } : d
		);

		setInstances(updatedInstances);
	};
	return loading ? (
		<Spinner />
	) : (
		<div className="content-padding min-height has-action-bar">
			<Container fluid className="px-0">
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Row xs={1} lg={2} className="mb-5">
						<Col className="mb-3 mb-lg-0">
							{/* Username (Read-only) */}
							<Form.Group as={Row} className="mb-3 align-items-center">
								<Form.Label column lg={3} className="text-lg-end">
									Username
								</Form.Label>
								<Col>
									<Form.Control
										type="text"
										placeholder="Enter name"
										readOnly
										{...register('password')}
										defaultValue={user.password}
									/>
								</Col>
							</Form.Group>

							{/* Full Name */}
							<Form.Group as={Row} className="mb-3 align-items-center">
								<Form.Label column lg={3} className="text-lg-end">
									Full Name
								</Form.Label>
								<Col>
									<Form.Control
										type="text"
										placeholder="Enter Full Name"
										{...register('username')}
										defaultValue={user.username}
									/>
								</Col>
							</Form.Group>

							{/* Correspondence Email */}
							<Form.Group as={Row} className="mb-3 align-items-center">
								<Form.Label column lg={3} className="text-lg-end">
									Correspondence Email
								</Form.Label>
								<Col>
									<Form.Control
										type="text"
										placeholder="Enter correspondence email"
										{...register('useremail')}
										defaultValue={user.useremail}
									/>
									{errors.useremail && (
										<span className="text-danger">
											{errors.useremail.message}
										</span>
									)}
								</Col>
							</Form.Group>

							{/* Account Management Email */}
							<Form.Group as={Row} className="mb-3 align-items-center">
								<Form.Label column lg={3} className="text-lg-end">
									Account Management Email
								</Form.Label>
								<Col>
									<Form.Control
										type="text"
										placeholder="Enter account management email"
										{...register('accountEmail')}
										defaultValue={user.accountEmail}
									/>
									{errors.accountEmail && (
										<span className="text-danger">
											{errors.accountEmail.message}
										</span>
									)}
								</Col>
							</Form.Group>

							{/* Title */}
							<Form.Group as={Row} className="mb-3 align-items-center">
								<Form.Label column lg={3} className="text-lg-end">
									Title
								</Form.Label>
								<Col>
									<Form.Control
										type="text"
										placeholder="Enter Title"
										{...register('usertitle')}
										defaultValue={user.usertitle || ''}
									/>
									{errors.usertitle && (
										<span className="text-danger">
											{errors.usertitle.message}
										</span>
									)}
								</Col>
							</Form.Group>

							{/* Employee Select */}
							<Form.Group as={Row} className="mb-3 align-items-center">
								<Form.Label column lg={3} className="text-lg-end">
									Employee
								</Form.Label>
								<Col>
									<Select
										options={employees}
										className="react-select"
										placeholder="Select employee"
										value={employees.find((emp) => emp.value === watch('emp'))}
										onChange={(option) => setValue('emp', option.value)}
									/>
								</Col>
							</Form.Group>
							<Form.Group as={Row} className="mb-3 align-items-center">
								<Form.Label
									column
									lg={3}
									className="d-flex align-items-center justify-content-lg-end m-0"
								>
									<span className="text-nowrap">Role</span>
									<RoleTooltip />
								</Form.Label>
								<Col>
									<UserRoleDropdown
										value={userRole}
										onChange={handleRoleChange}
										displayCustomRoleOption={
											user.authorizationTemplateType ===
											AuthorizationTemplateType.Custom
										}
									/>
								</Col>
							</Form.Group>

							{/* Administrator Checkbox */}
							<Form.Group as={Row} className="mb-3 align-items-center">
								<Form.Label column lg={3} className="text-lg-end">
									Account Manager
								</Form.Label>
								<Col>
									<Form.Check
										type="checkbox"
										label=""
										{...register('isAdmin')}
									/>
								</Col>
							</Form.Group>

							{/* Disabled Checkbox */}
							<Form.Group as={Row} className="mb-3 align-items-center">
								<Form.Label column lg={3} className="text-lg-end">
									Disabled
								</Form.Label>
								<Col>
									<Form.Check
										type="checkbox"
										label=""
										{...register('disabled')}
										defaultChecked={user.disabled}
									/>
								</Col>
							</Form.Group>
							{/* Form Instance */}
							<Form.Group>
								{allInstances?.length > 1 && (
									<Fragment>
										<Row className="text-lg-center mb-3 row">
											<Col>
												<Form.Label className="mb-0">
													Select the Design Manager copies theUser may access:
												</Form.Label>
											</Col>
										</Row>

										{allInstances?.length > 1 &&
											allInstances?.map((instance) => {
												const userInstance = instances?.find(
													(i) => i.InstanceId === instance.instanceId
												);
												return (
													<Row
														key={instance.instanceId}
														className="align-items-center mb-3"
													>
														<Col lg={3}></Col>
														<Col>
															<Form.Check
																inline
																label={instance.instanceName}
																type="checkbox"
																id={`instances-${instance.instanceId}`}
																onChange={(e) =>
																	handleInstanceChange(
																		instance.instanceId,
																		e.target.checked
																	)
																}
																checked={userInstance?.Enabled}
															/>
														</Col>
													</Row>
												);
											})}
									</Fragment>
								)}
							</Form.Group>
						</Col>
					</Row>
					<FooterFormAction>
						<Row className="align-items-center justify-content-end">
							<Col className="text-end" lg={5}>
								<Button type="submit" variant="primary" size="lg">
									Save
								</Button>
							</Col>
						</Row>
					</FooterFormAction>
				</Form>
			</Container>
		</div>
	);
};

const UserEdit = () => {
	return (
		<>
			<Header />
			<Content />
		</>
	);
};

export default UserEdit;
