import { Button, Form } from 'react-bootstrap';
import cn from 'classnames';
import { Table } from 'react-bootstrap';
import { useState } from 'react';

import AccountsDropdown from '../AccountsDropdown';
import SearchButton from '../SearchButton';

import { useGetAccountName } from '../hooks';

import { formatDate, formatMoney, searchData } from '../utils';

const sortData = (field, direction, data) => {
	if (!field) return data;
	return [...data].sort((a, b) => {
		const aField =
			field === 'statementdate' || field === 'computerdate'
				? new Date(a[field])
				: a[field];
		const bField =
			field === 'statementdate' || field === 'computerdate'
				? new Date(b[field])
				: b[field];

		if (aField < bField) {
			return direction === 'asc' ? -1 : 1;
		}

		if (aField > bField) {
			return direction === 'asc' ? 1 : -1;
		}

		return 0;
	});
};

const filterAccounts = (account, data) => {
	if (account === '') return data;

	return data.filter(({ ccaccount }) => ccaccount === account);
};

const flagLattest = (data) => {
	const sortedByDate = sortData('statementdate', 'desc', data);
	if (sortedByDate.length > 0) {
		sortedByDate[0].latest = true;
	}
	return sortedByDate;
};

function TableHeader({ searches, searching, setSearches, setSort, sort }) {
	const onSearchChange = ({ target: { name, value } }) => {
		setSearches((prev) => ({ ...prev, [name]: value }));
	};

	const [sortField, sortDirection] = sort;

	const onSpanClick = (field) => {
		if (sortField === field) {
			setSort((prev) => [field, prev[1] === 'asc' ? 'desc' : 'asc']);
		} else {
			setSort([field, 'desc']);
		}
	};

	const Span = ({ children, field }) => {
		const classes = cn('sort', {
			active: sortField === field,
			asc: sortDirection === 'asc',
			desc: sortDirection === 'desc',
		});
		return (
			<span className={classes} onClick={() => onSpanClick(field)}>
				{children}
			</span>
		);
	};

	return (
		<thead className="sticky-top">
			<tr className="a-table-heading">
				<th>
					<Span field="ccaccount">Account</Span>
				</th>
				<th>
					<Span field="statementdate">Statement Date</Span>
				</th>
				<th>
					<Span field="statementbalance">Ending Balance</Span>
				</th>
				<th>
					<Span field="computerdate">Completed On</Span>
				</th>
				<th>
					<Span field="userName">Completed By</Span>
				</th>
				<th></th>
				<th></th>
			</tr>
			{searching && (
				<tr className="a-table-search-fields">
					<th></th>
					<th>
						<Form.Control
							type="text"
							name="statementdate"
							onChange={onSearchChange}
							value={searches.statementdate}
						/>
					</th>
					<th>
						<Form.Control
							type="text"
							name="statementbalance"
							onChange={onSearchChange}
							value={searches.statementbalance}
						/>
					</th>
					<th>
						<Form.Control
							type="text"
							name="computerdate"
							onChange={onSearchChange}
							value={searches.computerdate}
						/>
					</th>
					<th>
						<Form.Control
							type="text"
							name="userName"
							onChange={onSearchChange}
							value={searches.userName}
						/>
					</th>
					<th></th>
					<th></th>
				</tr>
			)}
		</thead>
	);
}

function TableBody({
	onDownload,
	onVoid,
	query,
	searches,
	selectedAccount,
	sort,
}) {
	const { isLoading, data } = query;

	const getAccountName = useGetAccountName();

	const search = (data) =>
		Object.keys(searches).reduce(
			(prev, cur) => searchData(searches[cur], cur, prev),
			filterAccounts(selectedAccount, data)
		);

	const [sortField, sortDirection] = sort;

	return (
		<tbody>
			{isLoading ? (
				<>
					<tr className="tr-loading">
						<td className="td-1" colSpan={6}>
							<span>&nbsp;</span>
						</td>
					</tr>
					<tr className="tr-loading">
						<td className="td-1" colSpan={6}>
							<span>&nbsp;</span>
						</td>
					</tr>
				</>
			) : (
				sortData(sortField, sortDirection, search(flagLattest(data))).map(
					({
						ccaccount,
						computerdate,
						latest,
						statementdate,
						statementbalance,
						userName,
					}) => (
						<tr key={`${ccaccount}-${statementdate}`}>
							<td>{getAccountName(ccaccount)}</td>
							<td>{formatDate(statementdate)}</td>
							<td>{formatMoney(statementbalance)}</td>
							<td>{formatDate(computerdate)}</td>
							<td>{userName}</td>
							<td>
								<Button
									variant="link"
									className="text-primary"
									onClick={() =>
										onDownload({
											ccaccount,
											statementdate,
										})
									}
								>
									<i className="ri-file-pdf-fill"></i>{' '}
									<span className="text-decoration-underline">PDF</span>
								</Button>
							</td>
							<td>
								{latest && (
									<Button
										variant="link"
										className="text-danger"
										onClick={() =>
											onVoid({
												ccaccount,
												statementdate,
											})
										}
									>
										<i className="ri-file-close-fill"></i>{' '}
										<span className="text-decoration-underline">Void</span>
									</Button>
								)}
							</td>
						</tr>
					)
				)
			)}
		</tbody>
	);
}

export default function Grid({ query, onVoid, onDownload }) {
	const [searching, setSearching] = useState(false);
	const [searches, setSearches] = useState({});
	const [sort, setSort] = useState(['statementdate', 'desc']);
	const [selectedAccount, setSelectedAccount] = useState('');

	const onAccountChange = (selected) => {
		setSelectedAccount(selected.value);
	};

	return (
		<>
			<div className="a-filter border-1 border-sand bg-ivory rounderdx-4">
				<div className="d-flex flex-row justify-content-between align-items-center">
					<AccountsDropdown
						className="accounts-dropdown w-25"
						onChange={onAccountChange}
						topLabel="All Accounts"
					/>
					<SearchButton
						onClick={() => setSearching((prev) => !prev)}
						searching={searching}
					/>
				</div>
			</div>
			<div className="table-gradient sticky-container">
				<div className="table-responsive">
					<Table
						striped
						responsive
						className="a-table"
						id="cc-reconcile-history"
					>
						<TableHeader
							searching={searching}
							searches={searches}
							setSearches={setSearches}
							sort={sort}
							setSort={setSort}
						/>
						<TableBody
							onDownload={onDownload}
							onVoid={onVoid}
							query={query}
							searches={searches}
							selectedAccount={selectedAccount}
							sort={sort}
						/>
					</Table>
				</div>
			</div>
		</>
	);
}
