import React from 'react';
import { Container } from 'react-bootstrap';
import ThreadComponent from '../../ThreadComponent';
import { WithRouter } from '../../../../helpers/Router';
import { ApiService } from '../../../../lib/api/HttpService';
import { getSubComponent, Is } from '../../../../helpers/Util';
import { displayAlertError } from '../../../../utilities/Response';
import Documents from '../../documents/Documents';
import ItemAddSpecification from '../item-add/ItemAddSpecification';
import ItemAddPricing from '../item-add/ItemAddPricing';
import ItemAddStatus from '../item-add/ItemAddStatus';

class ItemAdd extends ThreadComponent {
	constructor(props) {
		super(props);
		this.api = new ApiService();

		this.state = {
			company: null,
			data: {},
			newId: '',
			showModal: true,
			isChanged: false,
			dataLoaded: false,
			isFound: true,
			stateUpdated: false,
			project: null,
			itemCompSort: 'comp asc',
			itemInvoiceSort: 'invoiceNumber asc',
			itemPropSort: 'proposalNumber asc',
			images: [],
		};
		this.errIds = [];

		this.compViews = [
			'compviewm',
			'compviewd',
			'compviewf',
			'compviewi',
			'compviewl',
			'compviewo',
		];
		window.clearedSelect = [];
	}

	componentInit() {
		this.updatedData = {};
		this.uploadedImage = null;
		this.id = this.props.params.itemId;
		this.projectId = parseInt(this.props.params.id);
		this.isEditing = this.props.params.itemId != null;

		this.setTitle(`${this.isEditing ? 'Edit' : 'Add'} Item`);
	}

	async componentDidMount() {
		const company = await this.api.get('company');
		let project = await this.api.getProjects(
			`?$filter=id eq ${parseInt(this.props.params.id)}`
		);
		project = project ? project[0] : 0;

		if (project) {
			this.setState({
				company,
				project,
			});
		}

		this.fetchItem();
	}

	async fetchItem() {
		this.props.setIsDoneLoading(false);
		await this.api
			.getProjectItems(`?$filter=id eq ${encodeURIComponent(this.id)}`)
			.then(async (res) => {
				const data = await this.getAdditionalData(res[0]);
				this.setIsLoaded(true);
				this.props.setIsDoneLoading(true);
				this.renderData(data);
			})
			.catch(() => {
				this.setState({
					stateUpdated: true,
				});
				this.setIsLoaded(true).setIsFound(false);
				displayAlertError(
					'There was an error getting all the information needed, please try again'
				);
			});
	}

	async getAdditionalData(data) {
		data = await this.addVendorsData(data);
		data = await this.addStatusData(data);
		data = await this.addProposalData(data);
		data = await this.addInvoiceData(data);
		data = await this.addItemComponentData(data);
		data = await this.addComponentTypeMapping(data);
		data = await this.addSpecificationTemplates(data);

		return data;
	}

	async addVendorsData(data) {
		// Vendors
		const vendors = await this.api.getVendors('?$filter=inactive eq false');
		data.defVendorValue = '';
		data.defVendorLabel = 'Please select';
		let vendorsList = [];
		for (let vendor of vendors) {
			vendorsList.push({
				value: vendor.vendor,
				label: vendor.vendorn + ` [${vendor.vendor}]`,
			});
			if (!Is.empty(data) && data.workroom && data.workroom === vendor.vendor) {
				data.defVendorValue = vendor.vendor;
				data.defVendorLabel = vendor.vendorn + ` [${vendor.vendor}]`;
			}
		}
		data.vendorsList = vendorsList;
		data.allVendors = vendors;
		return data;
	}

	async addStatusData(data) {
		// Status Data
		const statusCodes = await this.api.getStatusCodes('');

		data.defStatusValue = '';
		data.defStatusLabel = 'Specifying';

		let statusList = [];
		for (let status of statusCodes) {
			statusList.push({
				value: status.statusNumber,
				label: status.statusName,
			});

			if (
				!Is.empty(data) &&
				data.statusnumber &&
				data.statusnumber === status.statusNumber
			) {
				data.defStatusValue = status.statusNumber;
				data.defStatusLabel = status.statusName;
			}
		}

		data.statusList = statusList;

		return data;
	}

	async addProposalData(data) {
		// Proposal
		const proposals = await this.api.getItemProposals(
			`?$filter=projectCode eq '${encodeURIComponent(
				data.proj
			)}' AND itemNumber eq '${data.item}'&$orderby=${this.state.itemPropSort}`
		);
		data.proposals = proposals;

		return data;
	}

	async addInvoiceData(data) {
		// Client Invoice
		const invoices = await this.api.getItemInvoices(
			`?$filter=projectCode eq '${encodeURIComponent(
				data.proj
			)}' AND itemNumber eq '${data.item}'&$orderby=${
				this.state.itemInvoiceSort
			}`
		);
		data.invoices = invoices;

		return data;
	}

	async addItemComponentData(data) {
		// Item components
		const components = await this.api.getComponents(
			`?$filter=itemid eq ${this.props.params.itemId} AND projectid eq ${this.props.params.id}&$orderby=${this.state.itemCompSort}`
		);

		const itemComponents = await Promise.all(
			components.map(async (item) => {
				if (item.comptype !== 5) return item;

				const [{ txnum = null }] = await this.api.getTimeEntries(
					`?$filter=comp eq '${item.comp}' AND item eq '${item.item}' AND proj eq '${item.proj}'`
				);

				return { ...item, txnum };
			})
		);

		data.itemComponents = itemComponents;

		return data;
	}

	async addComponentTypeMapping(data) {
		const company = await this.api.get('company');
		data.componentTypes = {
			0: company.compviewm ?? '0',
			1: company.compviewd ?? '1',
			2: company.compviewf ?? '2',
			3: company.compviewi ?? '3',
			4: company.compviewl ?? '4',
			5: company.compviewo ?? '5',
		};

		return data;
	}

	async addSpecificationTemplates(data) {
		const templates = await this.api.getSpecificationTemplates('');
		data.specificationTemplates = templates;

		return data;
	}

	renderData(data) {
		this.setIsLoaded(true);

		this.setState({
			data: data ?? {},
		});
	}

	handleDataChange = (data) => {
		this.updatedData[data.key] = data.value;
		this.props.onChildrenDataChange(this.updatedData);
		this.setState({
			isChanged: true,
		});
	};

	getContent() {
		const { data } = this.state;

		return getSubComponent(this.props.activeMenu, {
			specification: (
				<ItemAddSpecification
					data={data}
					updatedData={this.updatedData}
					onDataChange={this.handleDataChange}
				/>
			),
			pricing: (
				<ItemAddPricing
					data={data}
					company={this.state.company}
					updatedData={this.updatedData}
					onDataChange={this.handleDataChange}
					changeSortOrder={async (objSort) => {
						const [key, sort] = Object.entries(objSort)[0];
						this.setState({
							[key]: sort,
						});
						try {
							this.props.setIsDoneLoading(false);
							await this.addItemComponentData(this.state.data);
							this.props.setIsDoneLoading(true);
						} catch {
							this.props.setIsDoneLoading(true);
							displayAlertError(
								'There was an error sorting the information, please try again'
							);
						}
					}}
				/>
			),
			status: (
				<ItemAddStatus
					data={data}
					updatedData={this.updatedData}
					onDataChange={this.handleDataChange}
					changeSortOrder={async (objSort) => {
						const [key, sort] = Object.entries(objSort)[0];
						this.setState({
							[key]: sort,
						});
						try {
							this.props.setIsDoneLoading(false);
							await this.addInvoiceData(this.state.data);
							await this.addProposalData(this.state.data);
							this.props.setIsDoneLoading(true);
						} catch {
							this.props.setIsDoneLoading(true);
							displayAlertError(
								'There was an error sorting the information, please try again'
							);
						}
					}}
				/>
			),
			documents: (
				<Documents
					objectType="Item"
					objectId={this.id}
					onRefresh={(numAttachments) => {
						this.props.setNumberOfDocuments(numAttachments);
					}}
				/>
			),
		});
	}

	render() {
		const invalidTabs = ['info', 'workroom'];
		return !invalidTabs.includes(this.props.activeMenu) ? (
			<div className="content-padding min-height has-action-bar">
				<Container fluid>{this.getContent()}</Container>
			</div>
		) : null;
	}
}

export default WithRouter(ItemAdd);
