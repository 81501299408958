import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Breadcrumb, Container, Button, Form, Row, Col } from 'react-bootstrap';
import ThreadComponent from '../../ThreadComponent';
import Header from '../../../components/Header';
import URI from '../../../../defaults/RoutesDefault';
import { getRouteWithParam, WithRouter } from '../../../../helpers/Router';
import { FooterFormAction } from '../../../components/Form';
import Select from 'react-select';
import imgStripe from '../../../../assets/images/stripe.png';
import imgDM from '../../../../assets/images/dm.png';
import { ApiService } from '../../../../lib/api/HttpService';
import {
	displayAlertError,
	displayAlertSuccess,
	getErrorMessage,
} from '../../../../utilities/Response';
import { isEmpty } from 'lodash';
import { isEmail } from '../../../../utilities/Validation';
import MSG from '../../../../defaults/Message';
import SecureBootstrapButton from '../../../../app/components/security/SecureBootstrapButton';
import { SECURITY_ATTRIBUTE_TYPES } from '../../../../app/context/security';
import { useFeature } from 'use-feature';
import { FeatureFlags } from 'legacy/app/enums/featureFlags/featureFlags';
import PaymentListSettingsNew from './PaymentListSettingsNew';

class PaymentListSettings extends ThreadComponent {
	constructor(props) {
		super(props);
		this.state = {
			stripeInstance: {},
			webSettings: {
				serialNumber: '',
				statusEmail: '',
				defAllowPaymentsProp: true,
				defAllowPaymentsInv: true,
				webPayType: 'STRIPE',
			},
			webSettingsOption: {},
			getComponents: {},
			taxtList: [],
			salesCategories: [],
			comps: ['m', 'd', 'f', 'i', 'l', 'o'],
			openStripe: false,
			serialNumber: null,
		};
		this.api = new ApiService();
	}

	componentInit() {
		this.setTitle('Payment Settings').setFormAction(true);
	}

	async componentDidMount() {
		this.dMloader(true);
		await this.handleConnectWithStripe();
		const salesCategories = await this.api.getSalesCategories();

		const categories = salesCategories.map((category) => {
			return {
				label: `${category.scatn} [${category.scat}]`,
				value: category.scat,
			};
		});

		this.setState({
			salesCategories: categories,
		});

		this.dMloader(false);
	}

	handleOpenStripe = async () => {
		this.setState({
			openStripe: true,
		});
		this.handleConnectWithStripe();
	};

	handleConnectWithStripe = async () => {
		try {
			this.dMloader(true, null, false);
			const stripeInstance = await this.api.connectStripe();
			const company = await this.api.getCompany();
			const companyTwo = await this.api.getCompany2();
			const serialNumber = company.serialNumber;
			let webSettings = await this.api.getWebSettings(
				`?$filter=serialNumber eq '${serialNumber}'`
			);
			const webSettingsOption = await this.api.getWebSettingsOptions();
			const getSalesCategories = await this.api.getSalesCategories();
			const searchSalesCategoriesFees = getSalesCategories.find(
				(item) => item.scat === companyTwo.processorFeeSalesCategory
			);

			if (!isEmpty(webSettings)) {
				webSettings = {
					...this.state.webSettings,
					...(webSettings[0] ?? webSettings),
				};
			} else {
				webSettings = this.state.webSettings;
			}

			let list = [];
			for (let i = 0; i < 5; i++) {
				{
					/* show v1 i < 6 */
				}
				list.push({
					value: i,
					label: company['compview' + this.state.comps[i]],
				});
			}

			if (stripeInstance.onboardingStatus === 'Verified') {
				this.setState({
					stripeInstance: stripeInstance,
					webSettings: webSettings,
					webSettingsOption: webSettingsOption,
					getComponents: list[companyTwo.processorFeeComponentType],
					salesCategoryFeesVal: {
						value: searchSalesCategoriesFees?.scat,
						label: `${searchSalesCategoriesFees?.scatn} [${searchSalesCategoriesFees?.scat}]`,
					},
					taxtList: list,
					comSerialNumber: company?.serialNumber,
				});

				history.pushState(
					null,
					'',
					getRouteWithParam(URI.settings.paymentSettings.connected, {
						connected: 'connected',
					})
				);
			} else {
				if (this.state.openStripe)
					window.location.href = stripeInstance.onboardingUrl;
			}
		} catch (error) {
			displayAlertError(getErrorMessage(error));
		}

		this.dMloader(false, true);
	};

	guestStripe() {
		return (
			<Row
				xs={1}
				lg={2}
				className="roundedx-4 py-3 mb-4 align-items-center justify-content-center text-center"
			>
				<Col>
					<Row className="align-items-center">
						<Col
							lg={5}
							className="d-flex justify-content-center me-md-auto ms-md-auto mb-2 mb-lg-0"
						>
							<Button
								variant="primary"
								// eslint-disable-next-line react/jsx-no-bind
								onClick={this.handleOpenStripe.bind()}
								size="lg"
								className="mx-1"
							>
								Connect with Stripe
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}

	onChangeWebSettings = (e) => {
		const { name, checked, value } = e.target;
		const webSettings = this.state.webSettings;
		if (name === 'statusEmail') {
			webSettings[name] = value;
		} else {
			webSettings[name] = checked;
		}

		this.setState({
			webSettings: webSettings,
		});
	};

	onChangeWebSettingsOption = (e) => {
		const { name, checked, value } = e.target;
		const webSettings = this.state.webSettingsOption;

		if (name === 'statusEmail') {
			webSettings[name] = value;
		} else {
			webSettings[name] = checked;
		}

		this.setState({
			webSettingsOption: webSettings,
		});
	};

	onChangeStripeInstance = (e) => {
		const { name, checked } = e.target;

		this.setState((prevState) => ({
			stripeInstance: {
				...prevState.stripeInstance,
				[name]: checked,
			},
		}));
	};

	handleClickSave = async () => {
		this.dMloader(true);
		const {
			webSettings: {
				statusEmail,
				defAllowPaymentsProp,
				defAllowPaymentsInv,
				webPayType,
				serialNumber,
			},
			comSerialNumber,
		} = this.state;
		try {
			await this.api.patchWebSettingsOptions({
				allowCreditCard: this.state.stripeInstance.allowsCreditCard,
				allowAch: this.state.stripeInstance.allowsAch,
				taxTransactionFee: this.state.webSettingsOption.taxTransactionFee,
				passACHFeesToClient: this.state.stripeInstance.passACHFeesToClient,
				passCreditCardFeesToClient:
					this.state.stripeInstance.passCreditCardFeesToClient,
			});

			await this.api.patchCompany2({
				processorFeeSalesCategory: this.state.salesCategoryFeesVal.value,
				processorFeeComponentType: this.state.getComponents?.value,
			});

			if (isEmpty(serialNumber)) {
				await this.api.postWebSettingsSerialOptions({
					statusEmail,
					defAllowPaymentsProp,
					defAllowPaymentsInv,
					webPayType,
					serialNumber: comSerialNumber,
				});
			} else {
				await this.api.patchWebSettingsSerialOptions(comSerialNumber, {
					statusEmail,
					defAllowPaymentsProp,
					defAllowPaymentsInv,
					webPayType,
				});
			}

			displayAlertSuccess('Payment settings saved succesfully.');
		} catch (error) {
			displayAlertError(getErrorMessage(error));
		}
		this.dMloader(false);
	};

	checkStripeConnect() {
		if (this.state.stripeInstance.onboardingStatus === 'Verified') {
			return this.stripeAccountConnected();
		} else {
			return this.guestStripe();
		}
	}

	stripeAccountConnected() {
		const {
			webSettings: { statusEmail },
			getComponents,
			stripeInstance: {
				allowsAch,
				passACHFeesToClient,
				allowsCreditCard,
				passCreditCardFeesToClient,
			},
			webSettingsOption: { taxTransactionFee },
		} = this.state;

		return (
			<>
				<Row xs={1} lg={2}>
					<Col>
						<Row className="align-items-center mb-3">
							<Col>
								<Form.Label className="mb-0">
									<strong>Default Settings</strong>
								</Form.Label>
							</Col>
						</Row>
					</Col>
				</Row>

				{/* <Row xs={1} lg={2}>
                <Col>
                    <Row className="align-items-center mb-3">
                        <Col lg={4} className="text-lg-end mb-2 mb-lg-0">
                            <Form.Label className="mb-0">Payment type that online payments are posted to</Form.Label>
                        </Col>
                        <Col lg={4}>
                            <Select options={fakeReactSelectOptions()} className="react-select" placeholder="Please select" />
                        </Col>
                    </Row>
                </Col>
            </Row> */}

				<Row xs={1} lg={2}>
					<Col>
						<Row className="align-items-center mb-4 mb-lg-5">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">Status Emails</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									placeholder="Please enter"
									name="statusEmail"
									value={statusEmail}
									onChange={this.onChangeWebSettings}
								/>
							</Col>
						</Row>
					</Col>
				</Row>

				<Row xs={1} lg={2}>
					<Col>
						<Row className="align-items-center mb-2 mb-lg-4">
							<Col>
								<Form.Label className="mb-0">
									<strong>Payments Allowed</strong>
								</Form.Label>
							</Col>
						</Row>
					</Col>
				</Row>

				<Row xs={1} lg={2}>
					<Col>
						<Row className="align-items-center mb-3">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">ACH</Form.Label>
							</Col>
							<Col>
								<Form.Check
									inline
									defaultChecked={allowsAch === true ? true : false}
									label="Allow"
									name="allowsAch"
									type="checkbox"
									id={`grouprd-check-ach-1`}
									className="vtop me-4 mb-2 mb-md-0"
									value={allowsAch === true ? true : false}
									onChange={this.onChangeStripeInstance}
								/>
								<Form.Check
									inline
									defaultChecked={passACHFeesToClient === true ? true : false}
									label="Charge Fees to the Client"
									name="passACHFeesToClient"
									type="checkbox"
									id={`grouprd-check-ach-2`}
									className="vtop mb-2 mb-md-0"
									value={passACHFeesToClient === true ? 1 : 0}
									onChange={this.onChangeStripeInstance}
								/>
							</Col>
						</Row>
					</Col>
				</Row>

				<Row xs={1} lg={2}>
					<Col>
						<Row className="align-items-center mb-4 mb-lg-5">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">Credit Card</Form.Label>
							</Col>
							<Col>
								<Form.Check
									inline
									defaultChecked={allowsCreditCard === true ? true : false}
									label="Allow"
									name="allowsCreditCard"
									type="checkbox"
									id={`grouprd-check-ach-3`}
									className="vtop me-4 mb-2 mb-md-0"
									value={allowsCreditCard === true ? 1 : 0}
									onChange={this.onChangeStripeInstance}
								/>
								<Form.Check
									inline
									defaultChecked={
										passCreditCardFeesToClient === true ? true : false
									}
									label="Charge Fees to the Client"
									name="passCreditCardFeesToClient"
									type="checkbox"
									id={`grouprd-check-ach-4`}
									className="vtop mb-2 mb-md-0"
									value={passCreditCardFeesToClient === true ? 1 : 0}
									onChange={this.onChangeStripeInstance}
								/>
							</Col>
						</Row>
					</Col>
				</Row>

				<Row xs={1} lg={2}>
					<Col>
						<Row className="align-items-center mb-0 mb-lg-4">
							<Col>
								<Form.Label className="mb-0">
									<strong>Tax Settings</strong>
								</Form.Label>
							</Col>
						</Row>
					</Col>
				</Row>

				<Row xs={1} lg={2}>
					<Col>
						<Row className="align-items-center mb-4">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0"></Col>
							<Col>
								<div className="d-flex align-items-center">
									<Form.Check
										inline
										defaultChecked={taxTransactionFee === true ? true : false}
										label="Taxable under type"
										name="taxTransactionFee"
										type="checkbox"
										id={`grouprd-check-tut-1`}
										className="vtop me-4"
										value={taxTransactionFee === true ? 1 : 0}
										onChange={this.onChangeWebSettingsOption}
									/>
									<Select
										options={this.state.taxtList}
										className="react-select w-50"
										placeholder={getComponents?.label}
										onChange={(option) => {
											this.setState({
												getComponents: option,
											});
										}}
										defaultValue={
											getComponents?.value
												? {
														value: getComponents?.value,
														label: getComponents?.label,
												  }
												: null
										}
									/>
								</div>
							</Col>
						</Row>
					</Col>
				</Row>

				<Row xs={1} lg={2}>
					<Col>
						<Row className="align-items-center mb-3">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">
									Sales Category for Fees
								</Form.Label>
							</Col>
							<Col>
								<Select
									options={this.state.salesCategories}
									className="react-select"
									placeholder={this.state.salesCategoryFeesVal}
									defaultValue={this.state.salesCategoryFeesVal}
									onChange={(option) => {
										this.setState({
											salesCategoryFeesVal: option,
										});
									}}
									name="salesCategoryFeesVal"
								/>
							</Col>
						</Row>
					</Col>
				</Row>

				{/* <Row xs={1} lg={2}>
                <Col>
                    <Row className="align-items-center mb-5">
                        <Col lg={3} className="mb-0"></Col>
                        <Col>
                            <Button variant='primary' disabled={true} className='w-100'>Enter TSYS/Cayan Credentials</Button>
                        </Col>
                    </Row>
                </Col>
            </Row> */}

				<FooterFormAction>
					<SecureBootstrapButton
						attributeNo={224}
						attributeType={SECURITY_ATTRIBUTE_TYPES.DenySpec1}
						variant="primary"
						size="lg"
						onClick={this.handleClickSave}
					>
						Save
					</SecureBootstrapButton>
				</FooterFormAction>
			</>
		);
	}

	renderHeader() {
		return (
			<Header>
				<Header.TitleContent>
					<Header.LeftContent>
						<Header.Breadcumbs>
							<NavLink
								to={URI.settings.list}
								className="text-white active d-flex align-items-center text-decoration-none fw-bold me-4 mb-3"
							>
								<i className="ri-arrow-left-s-line"></i> Back
							</NavLink>
							<Breadcrumb className="breadcrumb-light">
								<Breadcrumb.Item
									linkProps={{ to: URI.settings.list }}
									linkAs={Link}
								>
									Settings
								</Breadcrumb.Item>
								<Breadcrumb.Item>Payment Settings</Breadcrumb.Item>
							</Breadcrumb>
						</Header.Breadcumbs>
						<Header.Title>Payment Settings</Header.Title>
					</Header.LeftContent>
				</Header.TitleContent>
			</Header>
		);
	}

	renderContent() {
		return (
			<Form.Group>
				<Row className="bg-ivory align-items-center justify-content-center mb-4">
					<Col md={6} className="py-5 image-wrapper">
						<div className="d-flex align-items-center flex-wrap justify-content-center">
							<div className="d-flex align-items-center justify-content-center px-3 mb-3">
								<span>
									<img
										src={imgDM}
										width="160"
										height="auto"
										className="d-inline-block align-top"
										alt="React Bootstrap logo"
									/>
								</span>
								<span className="mx-2 mx-sm-4">
									<i className="d-none d-sm-block ri-add-line ri-2x text-secondary-ash"></i>
									<i className="d-sm-none ri-add-line ri-lg text-secondary-ash"></i>
								</span>
								<span>
									<img
										src={imgStripe}
										width="160"
										height="auto"
										className="d-inline-block align-top"
										alt="React Bootstrap logo"
									/>
								</span>
							</div>
							<div className="px-3 text-center w-100 fs-md fw-400">
								<p className="mb-0">
									Let your clients pay online to get paid faster and easier.
									Click Connect with Stripe below to get started accepting
									payments.
								</p>
								{/* <Link to={'/'} className='fs-sm'>Learn more.</Link> */}
								<a
									href="https://info.designmanager.com/stripe"
									target="_blank"
									rel="noopener noreferrer"
									className="fs-sm"
								>
									Learn more.
								</a>
							</div>
						</div>
					</Col>
				</Row>

				{this.checkStripeConnect()}
			</Form.Group>
		);
	}

	render() {
		return (
			<>
				{this.renderHeader()}

				<div className="content-padding min-height has-action-bar payment-settings">
					<Container fluid>{this.renderContent()}</Container>
				</div>
			</>
		);
	}
}

const PaymentListSettingsResolver = () => {
	const isRainforestEnabled = useFeature(FeatureFlags.RAINFOREST);

	if (isRainforestEnabled) {
		return <PaymentListSettingsNew />;
	}

	return <PaymentListSettings />;
};

export default WithRouter(PaymentListSettingsResolver);
