import Spinner from 'legacy/app/components/help/Spinner';
import SecureContent from 'legacy/app/components/security/SecureContent';
import { SECURITY_ATTRIBUTE_TYPES } from 'legacy/app/context/security';
import useStartPaymentProcessorSession from 'legacy/lib/api/hooks/useStartPaymentProcessorSession';
import { PaymentSessionType } from 'legacy/lib/api/types/PaymentSessionType';
import React from 'react';

const DepositsReport = () => {
	const {
		data: sessionInfo,
		isLoading,
		error,
	} = useStartPaymentProcessorSession(PaymentSessionType.DEPOSITREPORT);

	if (isLoading) {
		return <Spinner isChild />;
	}

	if (error) {
		return null;
	}

	return (
		<SecureContent
			attributeNo={54}
			attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAccess}
		>
			<SecureContent.HasAccess>
				<rainforest-deposit-report
					show-export-button
					session-key={sessionInfo?.sessionKey}
					data-filters={JSON.stringify({
						merchant_id: sessionInfo?.merchantId,
					})}
				/>
			</SecureContent.HasAccess>
			<SecureContent.NoAccess>
				<SecureContent.GenericNoAccess />
			</SecureContent.NoAccess>
		</SecureContent>
	);
};

DepositsReport.displayName = 'DepositsReport';

export default DepositsReport;
