import * as React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { generatePath } from 'react-router-dom';
import URI from '../../../defaults/RoutesDefault';
import ABox from '../../components/ABox';
import ThreadComponent from '../ThreadComponent';
import SecureContent from '../../../app/components/security/SecureContent';
import { SECURITY_ATTRIBUTE_TYPES } from '../../../app/context/security';
import AdminOnly from '../../../app/components/security/AdminOnly';

class SettingsList extends ThreadComponent {
	render() {
		return (
			<div className="content-padding">
				<Container fluid className="px-0">
					<Row>
						<Col>
							<h2 className="ff-type-bold mb-4 mb-md-5">Settings</h2>

							<ABox>
								<ABox.BoxItem boxsize="three" padding={0} border={0}>
									<AdminOnly>
										<ABox.CardLink to={URI.settings.userManagement.list}>
											<Row>
												<Col className="d-flex align-items-center">
													<div className="mb-auto pe-3">
														<i className="ri-group-2-fill fsx-42"></i>
													</div>
													<div className="pe-3 w-100">
														<h6 className="fs-md">
															<strong>User Management</strong>
														</h6>
														<p className="mb-0 fs-sm">
															Add and disable users, reset passwords.
														</p>
													</div>
													<div>
														<i className="ri-arrow-right-s-line ri-2x"></i>
													</div>
												</Col>
											</Row>
										</ABox.CardLink>
									</AdminOnly>

									<SecureContent
										attributeNo={224}
										attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAccess}
									>
										<SecureContent.HasAccess>
											<ABox.CardLink to={URI.settings.paymentSettings.list}>
												<Row>
													<Col className="d-flex align-items-center">
														<div className="mb-auto pe-3">
															<i className="ri-safe-fill fsx-42"></i>
														</div>
														<div className="pe-3 w-100">
															<h6 className="fs-md">
																<strong>Payment Settings</strong>
															</h6>
															<p className="mb-0 fs-sm">
																Connect your Design Manager account to Stripe to
																enable online client payments.
															</p>
														</div>
														<div>
															<i className="ri-arrow-right-s-line ri-2x"></i>
														</div>
													</Col>
												</Row>
											</ABox.CardLink>
										</SecureContent.HasAccess>
									</SecureContent>

									<SecureContent
										attributeNo={192}
										attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAccess}
									>
										<SecureContent.HasAccess>
											<ABox.CardLink to={URI.settings.userSettings.base}>
												<Row>
													<Col className="d-flex align-items-center">
														<div className="mb-auto pe-3">
															<i className="ri-user-fill fsx-42"></i>
														</div>
														<div className="pe-3 w-100">
															<h6 className="fs-md">
																<strong>User Settings</strong>
															</h6>
															<p className="mb-0 fs-sm">
																Enter a Name, Job Title and Email preferences
																for each user.
															</p>
														</div>
														<div>
															<i className="ri-arrow-right-s-line ri-2x"></i>
														</div>
													</Col>
												</Row>
											</ABox.CardLink>
										</SecureContent.HasAccess>
									</SecureContent>

									<SecureContent
										attributeNo={34}
										attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAccess}
									>
										<SecureContent.HasAccess>
											<ABox.CardLink to={URI.settings.companySettings.base}>
												<Row>
													<Col className="d-flex align-items-center">
														<div className="mb-auto pe-3">
															<i className="ri-group-2-fill fsx-42"></i>
														</div>
														<div className="pe-3 w-100">
															<h6 className="fs-md">
																<strong>Company Information</strong>
															</h6>
															<p className="mb-0 fs-sm">
																Include your company name, address, contact
																information and a logo to appear on your
																correspondences.
															</p>
														</div>
														<div>
															<i className="ri-arrow-right-s-line ri-2x"></i>
														</div>
													</Col>
												</Row>
											</ABox.CardLink>
										</SecureContent.HasAccess>
									</SecureContent>

									<ABox.CardLink to={URI.glossary.base}>
										<Row data-testid="glossary-option">
											<Col className="d-flex align-items-center">
												<div className="mb-auto pe-3">
													<i className="ri-booklet-fill fsx-42"></i>
												</div>
												<div className="pe-3 w-100">
													<h6 className="fs-md">
														<strong>Glossary</strong>
													</h6>
													<p className="mb-0 fs-sm">
														View, add and edit entries in your global
														glossaries.
													</p>
												</div>
												<div>
													<i className="ri-arrow-right-s-line ri-2x"></i>
												</div>
											</Col>
										</Row>
									</ABox.CardLink>

									<SecureContent
										attributeNo={34}
										attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAccess}
									>
										<SecureContent.HasAccess>
											<ABox.CardLink to={URI.accountSettings.base}>
												<Row>
													<Col className="d-flex align-items-center">
														<div className="mb-auto pe-3">
															<i className="ri-admin-fill fsx-42"></i>
														</div>
														<div className="pe-3 w-100">
															<h6 className="fs-md">
																<strong>Accounting Settings</strong>
															</h6>
															<p className="mb-0 fs-sm">
																Set default accounts for Sales, COGS, and more.
																Add Accounts Receivable & Accounts Payable
																payment types.
															</p>
														</div>
														<div>
															<i className="ri-arrow-right-s-line ri-2x"></i>
														</div>
													</Col>
												</Row>
											</ABox.CardLink>
										</SecureContent.HasAccess>
									</SecureContent>

									<SecureContent
										attributeNo={34}
										attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAccess}
									>
										<SecureContent.HasAccess>
											<ABox.CardLink to={URI.settings.workflowSettings.general}>
												<Row>
													<Col className="d-flex align-items-center">
														<div className="mb-auto pe-3">
															<i className="ri-bubble-chart-fill fsx-42"></i>
														</div>
														<div className="pe-3 w-100">
															<h6 className="fs-md">
																<strong>Workflow Settings</strong>
															</h6>
															<p className="mb-0 fs-sm">
																Set global defaults for Projects, Printing, Time
																and more.
															</p>
														</div>
														<div>
															<i className="ri-arrow-right-s-line ri-2x"></i>
														</div>
													</Col>
												</Row>
											</ABox.CardLink>
										</SecureContent.HasAccess>
									</SecureContent>
								</ABox.BoxItem>
							</ABox>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default SettingsList;
