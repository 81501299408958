import { Button } from 'react-bootstrap';

import cn from 'classnames';
import Stack from 'react-bootstrap/Stack';

import { useEffect, useState } from 'react';
import { useForm, useController, Controller } from 'react-hook-form';

import CurrencyInput from './CurrencyInput';

import { checkReconcile, startReconcile } from './api';
import AccountsDropdown from './AccountsDropdown';
import ReactDatePicker from 'react-datepicker';
import { format } from 'date-fns';

export default function ReconcileSetup({
	onCancel,
	onDownload,
	onSave,
	onStart,
	started,
}) {
	const [badStart, setBadStart] = useState(false);

	const {
		control,
		formState: { errors },
		handleSubmit,
		reset,
	} = useForm();

	const { field } = useController({
		name: 'account',
		control,
		rules: { required: true },
	});

	useEffect(() => {
		if (!started) {
			reset({
				account: '',
				statementDate: '',
				endingBalance: '',
			});
		}
	}, [started]);

	const onSubmit = async (data) => {
		const response = await checkReconcile(
			data.account.value,
			format(new Date(data.statementDate), 'yyyy-MM-dd')
		);

		if (response.length > 0) {
			setBadStart(true);
		} else {
			setBadStart(false);

			await startReconcile({
				StatementEndingDate: format(new Date(data.statementDate), 'yyyy/MM/dd'),
				CreditCardAccount: data.account.value,
			});

			onStart(data);
		}
	};

	const selectClasses = cn({
		border: !!errors.account,
		'border-danger': !!errors.account,
		rounded: !!errors.account,
	});
	const statementDateClasses = cn({ 'is-invalid': !!errors.statementDate });
	const endingBalanceClasses = cn('form-control', {
		'is-invalid': !!errors.endingBalance,
	});

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="bg-ivory light a-header pb-3">
				<Stack direction="horizontal" gap={3} className="align-items-end">
					<div className="w-25">
						Account
						<AccountsDropdown
							{...field}
							className={`${selectClasses} tw-h-10`}
							isDisabled={started}
						/>
					</div>
					<div>
						<span className="tw-block">Statement Date</span>
						<Controller
							control={control}
							name="statementDate"
							render={({ field: { onChange, value } }) => (
								<ReactDatePicker
									dateFormat="MM/dd/yyyy"
									onChange={onChange}
									className={`${statementDateClasses} form-control form-control-sm tw-h-10`}
									showMonthDropdown={true}
									showYearDropdown={true}
									placeholderText="mm-dd-yyyy"
									autoComplete="off"
									selected={value}
								/>
							)}
						/>
					</div>
					<div>
						Statement Ending Balance
						<CurrencyInput
							defaultValue={0}
							className={endingBalanceClasses}
							disabled={started}
							name="endingBalance"
							control={control}
							rules={{ required: true }}
						/>
					</div>
					<div>
						{!started ? (
							<Button variant="primary" type="submit" disabled={started}>
								<>Start Reconcile</>
							</Button>
						) : (
							<Stack direction="horizontal" gap={3}>
								<Button variant="outline-dark ms-auto" onClick={onCancel}>
									Cancel
								</Button>
								<Button variant="primary" onClick={onSave}>
									Save
								</Button>
								<Button variant="outline-dark ms-auto" onClick={onDownload}>
									Get PDF
								</Button>
							</Stack>
						)}
					</div>
				</Stack>
				{badStart && (
					<div className="mt-4 alert alert-danger">
						There is already a reconciliation for this account that is newer or
						the same as this one. Please use the history button to view
						completed reconciliations.
					</div>
				)}
			</div>
		</form>
	);
}
