import React from 'react';

import Dropdown from 'react-bootstrap/Dropdown';

import { useHasAccess } from '../../../context/security';

export default function SecureDropdownItem({
	attributeNo,
	attributeType,
	children,
	disabled,
	title,
	...props
}) {
	const hasAccess = useHasAccess(attributeNo, attributeType);

	const isDisabled = !hasAccess ? true : disabled;
	const titleToUse = !hasAccess
		? 'The assigned user role restricts access to this action'
		: title;

	return (
		<Dropdown.Item {...props} disabled={isDisabled}>
			{children}
		</Dropdown.Item>
	);
}
