import axios, { AxiosError } from 'axios';
import * as querystring from 'query-string';
import { v4 } from 'uuid';
import { AppConfiguration } from '../../config/Configuration';
import { AuthResponse } from '../networking/HttpWorker';
import { generatePath } from 'react-router-dom';
import URI from '../../defaults/RoutesDefault';
import { getCookie } from '../../utilities/Auth';

interface HttpWorkerConfiguration {
	baseApiURL: string;
	baseAuthURL: string;
	headers: {};
	headersAuth: {};
	headersApi: {
		Accept: string;
		'Content-Type': string;
		'X-InstanceId': string;
		'X-ApiClientId': string;
		'X-AppVersion': string;
		Authorization: string;
		responseType: string;
	};
}

export enum HttpWorkerErrorType {
	invalidRequest = 'INVALID_REQUEST',
	noInternetConnection = 'NO_INTERNET_CONNECTION',
	timeout = 'TIMEOUT',
}

export class HttpWorkerConfigurationFactory {
	static baseAuthURL = AppConfiguration.default.apiAuthBaseUrl;
	static baseApiURL = AppConfiguration.default.apiBaseUrl;

	static api: HttpWorkerConfiguration = {
		baseAuthURL: `${HttpWorkerConfigurationFactory.baseAuthURL}`,
		baseApiURL: `${HttpWorkerConfigurationFactory.baseApiURL}`,
		headers: {},
		headersAuth: { 'Content-Type': 'application/x-www-form-urlencoded' },
		headersApi: {
			Accept: 'application/json',
			'Content-Type': 'application/x-www-form-urlencoded',
			'X-InstanceId': '',
			'X-ApiClientId': '42919f39-4705-447e-b93f-de5373c57b62',
			'X-AppVersion': process.env.REACT_APP_VERSION || 'not set',
			Authorization: '',
			responseType: 'json',
		},
	};
}

export class HttpWorker {
	private configuration: HttpWorkerConfiguration;

	constructor(configuration: HttpWorkerConfiguration) {
		this.configuration = configuration;
	}

	async get<T>(route: string, params: {}): Promise<T> {
		try {
			const config = this.configuration;
			const token = await this.getToken();
			const instanceId = getCookie('dmInstanceId') || '';
			const newHeaders = {
				...config,
				headers: {
					...config.headers,
					'X-InstanceId': instanceId,
					Authorization: `Bearer ${token}`,
					'X-Correlation-Id': v4(),
				},
			};
			const response = await axios.get(route, {
				...newHeaders,
				params: params,
			});
			return response.data as T;
		} catch (error) {
			if (error instanceof AxiosError) {
				if (error.request) {
					// Not authorized to perform this action.
					if (
						error?.response?.status === 403 ||
						error?.response?.status === 401
					) {
						throw error;
					}
					if (error.code === 'ECONNABORTED') {
						throw new HttpWorkerError(HttpWorkerErrorType.timeout, error);
					} else {
						throw new HttpWorkerError(
							HttpWorkerErrorType.noInternetConnection,
							error
						);
					}
				}
			}
			throw error;
		}
	}

	async patch<T>(route: string, params: {}, isBlob?: boolean): Promise<T> {
		const config: any = this.configuration;
		const headers = config.headers;
		const token = await this.getToken();
		const instanceId = getCookie('dmInstanceId') || '';
		const newHeaders = {
			...config,
			headers: {
				...headers,
				'X-InstanceId': instanceId,
				'Content-Type': isBlob ? 'multipart/form-data' : 'application/json',
				dataType: 'json',
				Authorization: `Bearer ${token}`,
				'X-Correlation-Id': v4(),
			},
		};
		if (isBlob) {
			delete newHeaders.dataType;
		}

		return new Promise((resolve, reject) => {
			axios
				.patch(route, params, newHeaders)
				.then((response) => {
					resolve(response.data as T);
				})
				.catch(function (error: AxiosError) {
					reject(error);
				});
		});
	}

	async getFile<T>(route: string, params: {}): Promise<T> {
		const config = this.configuration;
		const token = await this.getToken();
		const instanceId = getCookie('dmInstanceId') || '';
		const newConfig = {
			...config,
			headers: {
				...config.headers,
				'X-InstanceId': instanceId,
				Authorization: `Bearer ${token}`,
				'X-Correlation-Id': v4(),
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		};

		const response = await fetch(route, { ...newConfig });
		return response.blob() as T;
	}

	async saveFile<T>(route: string, params: {}): Promise<T> {
		const token = await this.getToken();
		const instanceId = getCookie('dmInstanceId') || '';
		const config = {
			...this.configuration,
			headers: {
				...this.configuration.headers,
				'X-InstanceId': instanceId,
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${token}`,
				'X-Correlation-Id': v4(),
			},
		};

		return new Promise((resolve, reject) => {
			axios
				.post(route, params, config)
				.then((response) => {
					resolve(response.data as T);
				})
				.catch(function (error: AxiosError) {
					reject(error);
				});
		});
	}

	async editFile<T>(route: string, params: {}): Promise<T> {
		const token = this.getToken();
		const instanceId = getCookie('dmInstanceId') || '';
		const config = {
			...this.configuration,
			headers: {
				...this.configuration.headers,
				'X-InstanceId': instanceId,
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${token}`,
				'X-Correlation-Id': v4(),
			},
		};

		return new Promise((resolve, reject) => {
			axios
				.patch(route, params, config)
				.then((response) => {
					resolve(response.data as T);
				})
				.catch(function (error: AxiosError) {
					reject(error);
				});
		});
	}

	async saveItemCaptureImage<T>(route: string, params: FormData): Promise<T> {
		const token = await this.getToken();
		const instanceId = getCookie('dmInstanceId') || '';
		const config = {
			...this.configuration,
			headers: {
				...this.configuration.headers,
				'X-InstanceId': instanceId,
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${token}`,
				'X-Correlation-Id': v4(),
			},
		};

		return new Promise((resolve, reject) => {
			axios
				.post(route, params, config)
				.then((response) => {
					resolve(response.data as T);
				})
				.catch(function (error: AxiosError) {
					reject(error);
				});
		});
	}

	async post<T>(path: string, params: {}): Promise<T> {
		const token = await this.getToken();
		const instanceId = getCookie('dmInstanceId') || '';
		const config = {
			...this.configuration,
			headers: {
				...this.configuration.headers,
				'X-InstanceId': instanceId,
				Authorization: `Bearer ${token}`,
				'X-Correlation-Id': v4(),
			},
		};

		//@ts-ignore
		//config.headers['Content-Type'] = 'application/json';
		return new Promise((resolve, reject) => {
			axios
				.post(path, params, config)
				.then((response) => {
					resolve(response.data as T);
				})
				.catch(function (error: AxiosError) {
					reject(error);
				});
		});
	}

	async postJson<T>(path: string, params: {}): Promise<T> {
		const token = await this.getToken();
		const instanceId = getCookie('dmInstanceId') || '';
		const config = {
			...this.configuration,
			headers: {
				...this.configuration.headers,
				'X-InstanceId': instanceId,
				'Content-Type': 'application/json',
				dataType: 'json',
				Authorization: `Bearer ${token}`,
				'X-Correlation-Id': v4(),
			},
		};

		return new Promise((resolve, reject) => {
			axios
				.post(path, params, config)
				.then((response) => {
					resolve(response.data as T);
				})
				.catch(function (error: AxiosError) {
					reject(error);
				});
		});
	}

	async postForm<T>(path: string, params: {}): Promise<T> {
		const token = await this.getToken();
		const instanceId = getCookie('dmInstanceId') || '';
		const config = {
			...this.configuration,
			headers: {
				...this.configuration.headers,
				'X-InstanceId': instanceId,
				Authorization: `Bearer ${token}`,
				'X-Correlation-Id': v4(),
			},
		};

		return new Promise((resolve, reject) => {
			axios
				.post(path, querystring.stringify(params), config)
				.then((response) => {
					resolve(response.data as T);
				})
				.catch(function (error: AxiosError) {
					reject(error);
				});
		});
	}

	async put<T>(route: string, params: {}): Promise<T> {
		const token = await this.getToken();
		const instanceId = getCookie('dmInstanceId') || '';
		const config = {
			...this.configuration,
			headers: {
				...this.configuration.headers,
				'X-InstanceId': instanceId,
				'Content-Type': 'application/json',
				dataType: 'json',
				Authorization: `Bearer ${token}`,
				'X-Correlation-Id': v4(),
			},
		};

		return new Promise((resolve, reject) => {
			axios
				.put(route, params, config)
				.then((response) => {
					resolve(response.data as T);
				})
				.catch(function (error: AxiosError) {
					reject(error);
				});
		});
	}

	async delete<T>(route: string, params: {}): Promise<T> {
		try {
			const token = await this.getToken();
			const instanceId = getCookie('dmInstanceId') || '';
			const config = {
				...this.configuration,
				headers: {
					...this.configuration.headers,
					'X-InstanceId': instanceId,
					Authorization: `Bearer ${token}`,
					'X-Correlation-Id': v4(),
				},
			};

			const response = await axios.delete(route, {
				...config,
				params: params,
			});
			return response as T;
		} catch (error) {
			if (error instanceof AxiosError) {
				if (error.request) {
					if (error.code === 'ECONNABORTED') {
						throw new HttpWorkerError(HttpWorkerErrorType.timeout, error);
					} else if (
						error.code === 'ERR_BAD_REQUEST' ||
						400 === error?.response?.status
					) {
						// Please throw the real error.
						throw error;
					} else {
						throw new HttpWorkerError(
							HttpWorkerErrorType.noInternetConnection,
							error
						);
					}
				}
			}
			throw error;
		}
	}

	private getToken(): string {
		const token = getCookie('dmAuthToken');
		if (token && token !== '') {
			return token;
		} else {
			window.location.href = generatePath(URI.login.base);
			return '';
		}
	}

	getAuthToken<T = AuthResponse>(params: {}): Promise<T> {
		const config = {
			baseURL: HttpWorkerConfigurationFactory.baseAuthURL,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'X-Correlation-Id': v4(),
			},
		};

		return new Promise((resolve, reject) => {
			axios
				.post('/connect/token', params, config)
				.then((response) => {
					resolve(response.data as T);
				})
				.catch(function (error: AxiosError) {
					reject(error);
				});
		});
	}

	async getPublic<T>(route: string, params: {}): Promise<T> {
		try {
			const config = {
				baseURL: HttpWorkerConfigurationFactory.baseApiURL,
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					'X-Correlation-Id': v4(),
				},
			};
			const response = await axios.get(route, {
				...config,
				params: params,
			});
			return response.data as T;
		} catch (error) {
			if (error instanceof AxiosError) {
				if (error.request) {
					if (error.code === 'ECONNABORTED') {
						throw new HttpWorkerError(HttpWorkerErrorType.timeout, error);
					} else {
						throw new HttpWorkerError(
							HttpWorkerErrorType.noInternetConnection,
							error
						);
					}
				}
			}
			throw error;
		}
	}

	async postJsonPublic<T>(path: string, params: {}): Promise<T> {
		const config = {
			baseURL: HttpWorkerConfigurationFactory.baseApiURL,
			headers: {
				'Content-Type': 'application/json',
				dataType: 'json',
				'X-Correlation-Id': v4(),
			},
		};

		//@ts-ignore
		//config.headers['Content-Type'] = 'application/json';
		return new Promise((resolve, reject) => {
			axios
				.post(path, params, config)
				.then((response) => {
					resolve(response.data as T);
				})
				.catch(function (error: AxiosError) {
					reject(error);
				});
		});
	}
}

export class HttpWorkerError extends Error {
	get type() {
		return this._type;
	}
	get backedError() {
		return this._backedError;
	}

	private _type: HttpWorkerErrorType;
	private _backedError: AxiosError;

	constructor(errorType: HttpWorkerErrorType, backedError: AxiosError) {
		super(errorType);
		this._type = errorType;
		this._backedError = backedError;
		Object.setPrototypeOf(this, HttpWorkerError.prototype);
	}
}
