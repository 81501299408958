import cn from 'classnames';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function TabControl({ activeTab, setTab }) {
	const TabControl = ({ name, children, ...props }) => {
		const classes = cn({ active: activeTab === name });

		return (
			<li>
				<Button {...props} className={classes} as={Link}>
					{children}
				</Button>
			</li>
		);
	};

	return (
		<div className="bg-ivory light a-header pb-2 pt-3">
			<ul className="a-header-buttons d-flex list-unstyled text-charcoal mb-0 p-0">
				<TabControl name="outstanding" onClick={() => setTab('outstanding')}>
					Outstanding
				</TabControl>
				<TabControl name="cleared" onClick={() => setTab('cleared')}>
					Cleared
				</TabControl>
			</ul>
		</div>
	);
}
