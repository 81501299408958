import { Component } from 'react';
import { Container } from 'react-bootstrap';
import loader from '../../../assets/images/loader.svg';
import loaderCircle from '../../../assets/images/loader-ring.svg';
import logo from '../../../assets/images/logo-dark.svg';

interface Props {
	isChild?: boolean;
}

class Spinner extends Component<Props> {
	constructor(props: Props) {
		super(props);
	}

	renderDotLoader() {
		const header: any = document.querySelector('.a-header');
		const headerHeight = parseInt(header?.offsetHeight || 200) + 80;

		return (
			<div
				className="content-padding min-height spinner-div"
				data-testid="dot-spinner"
			>
				<Container fluid>
					<div
						className="d-flex justify-content-center align-items-center"
						style={{
							height: `calc(85vh - ${this.props.isChild ? headerHeight : 0}px)`,
						}}
					>
						<div>
							<img
								src={logo}
								width="180"
								height="auto"
								className="d-inline-block align-top"
								alt="React Bootstrap logo"
							/>
							<br></br>
							<img
								src={loader}
								width="180"
								height="130"
								className="d-inline-block align-top"
								alt="React Bootstrap logo"
								style={{
									marginTop: '-40px',
								}}
							/>
						</div>
					</div>
				</Container>
			</div>
		);
	}

	renderCircularLoader() {
		const header: any = document.querySelector('.a-header');
		const headerHeight = parseInt(header?.offsetHeight || 200) + 80;

		return (
			<div
				className="content-padding min-height spinner-div"
				data-testid="circle-spinner"
			>
				<Container fluid>
					<div
						className="d-flex justify-content-center align-items-center text-center"
						style={{
							height: `calc(85vh - ${this.props.isChild ? headerHeight : 0}px)`,
						}}
					>
						<div>
							<img
								src={logo}
								width="120"
								height="auto"
								className="d-inline-block align-top"
								alt="React Bootstrap logo"
							/>
							<br></br>
							<img
								src={loaderCircle}
								width="200"
								height="auto"
								className="d-inline-block align-top"
								alt="React Bootstrap logo"
								style={{
									marginTop: '-118px',
								}}
							/>
						</div>
					</div>
				</Container>
			</div>
		);
	}

	render(): JSX.Element {
		return <>{this.renderDotLoader()}</>;
	}
}

export default Spinner;
