import { WhatChanged } from 'legacy/lib/api/types/WhatChangedItems';
import { FormControl } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';
import { VALID_PERCENTAGE_NUMBER } from 'legacy/resources/regex/validPercentageNumber';

const QuantityInput = ({
	handleRecalculate,
	disabled,
}: {
	disabled: boolean;
	handleRecalculate: (whatPropertyChanged: `${WhatChanged}`) => void;
}) => {
	const methods = useFormContext();

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const isValidNumber = VALID_PERCENTAGE_NUMBER.test(e.target.value);
		if (isValidNumber || !e.target.value || e.target.value === '-') {
			methods.setValue('quantity', e.target.value);
		}
	};

	return (
		<Controller
			control={methods.control}
			name={'quantity'}
			render={({ field: { value } }) => (
				<FormControl
					className="tw-h-8 tw-lg:col-span-1"
					onChange={handleChange}
					maxLength={15}
					value={value}
					onBlur={(e) => {
						const isNotAValidNumber = isNaN(Number(e.target.value));

						if (
							!e.target.value ||
							isNotAValidNumber ||
							e.target.value === '-0.'
						) {
							methods.setValue('quantity', '0');
						} else {
							methods.setValue('quantity', String(Number(e.target.value)));
						}
						handleRecalculate(WhatChanged.QUANTITY);
					}}
					placeholder="0"
					disabled={disabled}
				/>
			)}
		/>
	);
};

QuantityInput.displayName = 'QuantityInput';

export default QuantityInput;
