import React from 'react';
import { Button } from 'react-bootstrap';
import URI from '../../../defaults/RoutesDefault';
import { getLocalStorage } from '../../../utilities/LocalStorage';
import { getCookie, parseJwt, setCookie } from '../../../utilities/Auth';
import ThreadComponent from '../../ThreadComponent';
import { delay, isEmpty } from 'lodash';
import {
	API_AUTH_ERROR_MESSAGES,
	ApiService,
} from '../../../lib/api/HttpService';

interface Props {
	onLogout?: any;
}

interface State {
	isOpen: boolean;
}

interface User {
	name: string;
	email: string;
}

class AccountSwitcherDropdown extends ThreadComponent<Props, State> {
	protected dmInstances: any;
	protected currentDmInstance: any;
	protected currentDmInstanceId: string;
	protected userInfo: User;
	readonly api: ApiService;

	constructor(props: Props) {
		super(props);

		this.state = {
			isOpen: false,
		};

		this.userInfo = {
			name: getCookie('dmUsername'),
			email: getCookie('dmUserEmail'),
		};

		this.dmInstances = getLocalStorage('userInstances', true);
		this.dmInstances = isEmpty(this.dmInstances) ? [] : this.dmInstances;

		this.currentDmInstanceId = getCookie('dmInstanceId');
		this.currentDmInstance = this.getCurrentInstance();

		// @todo: replace this with a onClickOutside type of check
		document.addEventListener('click', (e: any) => {
			if (
				this.state.isOpen &&
				e.target &&
				!e.target.classList.contains('account-switcher-handle')
			) {
				this.setOpen(false);
			}
		});

		this.api = new ApiService();
	}

	getCurrentInstance() {
		return !isEmpty(this.dmInstances)
			? this.dmInstances.find(
					(instance: any) => instance.Id === this.currentDmInstanceId
			  )
			: {};
	}

	handleSelectEnvironment = async (dmId: any) => {
		if (dmId === this.currentDmInstanceId) return;

		this.dMloader(
			{ show: true, message: 'Switching environment', noOpacity: true },
			false,
			false
		);
		let userCookie = getCookie('dmLoginUsername');
		const token = getCookie('dmAuthToken');
		const parsedToken = parseJwt(token);
		if (parsedToken?.ImpersonationMode) {
			// Impersonated users will have an @@ in their accounts, e.g user1@@user2 so the actual logged in user will be user2
			const splitCookie = userCookie.split('@@');
			userCookie = splitCookie[1];
		}
		setCookie('dmInstanceId', dmId, 7);
		await this.api
			.getUserData(`?$filter=password eq '${encodeURIComponent(userCookie)}'`)
			.then((resp) => {
				// @ts-ignore
				const [data] = resp;

				setCookie('dmUsercode', data.usercode, 7);
				delay(() => {
					window.location.href = '/';
				}, 1000);
			})
			.catch((err) => {
				if (
					err?.response?.data?.UserError ===
					API_AUTH_ERROR_MESSAGES.INVALID_INSTANCE
				) {
					window.location.href = URI.invalidInstance;
				}
				this.dMloader(
					{
						show: true,
						message: 'Failed to get user data',
						noOpacity: true,
					},
					false,
					false
				);
			});
	};

	setOpen(isOpen: boolean) {
		this.setState({
			isOpen,
		});
	}

	isOpen() {
		return this.state.isOpen;
	}

	isActive(dmId?: string) {
		return dmId === this.currentDmInstanceId;
	}

	render() {
		return (
			<div data-bs-theme="dark" className="d-block" id="user-accounts-active">
				<div
					className={`${
						this.state.isOpen ? 'd-flex' : 'd-none'
					} flex-column bg-light rounded mb-2`}
				>
					<div className="p-3 pb-0 border-bottom">
						<h5 className="ellipses mb-0">{this.userInfo.name}</h5>
						<p className="ellipses">
							{isEmpty(this.userInfo.email) || this.userInfo.email === 'null'
								? ''
								: this.userInfo.email}
						</p>
					</div>
					<div className="d-flex flex-wrap w-100">
						<div className="d-flex flex-column option-select w-100">
							{this.dmInstances?.map((instance: any, i: number) => (
								<div
									className={`form-input-wrapper w-100 p-3 border-bottom ${
										this.isActive(instance.Id) ? '' : 'bg-beige'
									}`}
									key={i}
									onClick={() => this.handleSelectEnvironment(instance.Id)}
								>
									<div className="form-check ps-0 m-0">
										<input
											className={`form-check-input ms-0 ${
												this.isActive(instance.Id) ? '' : 'd-none'
											}`}
											type="radio"
											name={instance.Id}
											id={'option-' + i}
											value={instance.Id}
											checked={instance.Id === this.currentDmInstanceId}
											onChange={() => this.handleSelectEnvironment(instance.Id)}
										/>
										<label className="ellipses" htmlFor={'option-' + i}>
											{instance.Name}
										</label>
									</div>
								</div>
							))}
						</div>
						<div className="d-flex justify-content-end ms-auto py-2 px-3">
							<Button
								href={URI.login.base}
								onClick={(e) => {
									this.setOpen(false);
									this.props?.onLogout && this.props?.onLogout();
									e.preventDefault();
								}}
								size="sm"
							>
								Sign Out
							</Button>
						</div>
					</div>
				</div>

				<div
					className="d-flex w-100 p-1 d-block account-switcher-handle"
					onClick={() => {
						this.setOpen(!this.state.isOpen);
					}}
					style={{
						cursor: 'pointer',
					}}
				>
					<div className="text-light flex-grow-1 names">
						<h6 className="ellipses fw-bold">{this.userInfo.name}</h6>
						<p className="ellipses">{this.currentDmInstance?.Name}</p>
					</div>
					<div className="d-flex align-items-center arrow">
						<i
							className={`ri-arrow-down-s-line fsx-24 text-white ${
								this.isOpen() ? 'open' : ''
							}`}
						></i>
					</div>
				</div>
			</div>
		);
	}
}

export default AccountSwitcherDropdown;
