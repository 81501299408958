import React from 'react';
import {
	DndContext,
	closestCenter,
	MouseSensor,
	TouchSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import {
	arrayMove,
	SortableContext,
	horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import { TGalleryImages, TItemImage } from 'legacy/lib/api/types/ItemImages';
import SortableImageDragAndDrop from 'legacy/app/components/ThumbnailGallery/SortableImageDragAndDrop';

interface IThumbnailGalleryProps {
	images: TItemImage[];
	setSelectedImage: (value: TGalleryImages) => void;
	selectedImageIndex: number;
	handleImages: (images: TItemImage[]) => void;
}

export const ImageGalleryThumbnails = ({
	images = [],
	setSelectedImage,
	selectedImageIndex,
	handleImages,
}: IThumbnailGalleryProps) => {
	const filledImages = [
		...images,
		...Array(4 - images.length).fill(null),
	].slice(0, 4);

	const firstNullIndex = filledImages?.findIndex((image) => image === null);
	const placeholderStartIndex = firstNullIndex <= 2 ? firstNullIndex + 1 : null;

	const sensors = useSensors(
		useSensor(MouseSensor),
		useSensor(TouchSensor, {
			activationConstraint: {
				delay: 250,
				tolerance: 5,
			},
		})
	);

	const handleDragEnd = (event: any) => {
		const { active, over } = event;

		if (!active || !over) return;

		if (active.id !== over.id) {
			const oldIndex = images.findIndex(
				(item, idx: number) => idx.toString() === active.id
			);
			const newIndex = images.findIndex(
				(item, idx: number) => idx.toString() === over.id
			);

			const newImages = arrayMove(images, oldIndex, newIndex) as TItemImage[];

			setSelectedImage({
				file: newImages[newIndex]?.file,
				index: newIndex,
			});
			handleImages(newImages);
		}
	};

	const handleImageAdd = (newImage: File, index: number) => {
		const updatedImages = [...images];
		updatedImages[index] = { file: newImage, fileId: null };

		handleImages(updatedImages);
		setSelectedImage({ file: newImage, index });
		handleImages(updatedImages);
	};

	const handleDragStart = (event: any) => {
		const { active } = event;
		const draggedIndex = active.id;
		setSelectedImage({
			file: filledImages[draggedIndex]?.file,
			index: Number(draggedIndex),
		});
	};

	const handleDelete = (index: number) => {
		const updatedImages = [...images];
		updatedImages.splice(index, 1);
		handleImages(updatedImages);
		const selectedImageFile = updatedImages[0]?.file;
		setSelectedImage({ file: selectedImageFile, index: 0 });
	};

	return (
		<div className="tw-mt-5 tw-min-h-24 md:tw-content-end">
			<DndContext
				sensors={sensors}
				collisionDetection={closestCenter}
				onDragEnd={handleDragEnd}
				onDragStart={handleDragStart}
			>
				<SortableContext
					items={filledImages?.map((_, index) => index.toString())}
					strategy={horizontalListSortingStrategy}
				>
					<div className="tw-grid tw-grid-cols-2 md:tw-grid-cols-4 tw-grid-rows-1 tw-gap-4 lg:tw-gap-8">
						{filledImages?.map((image, index) => (
							<div
								className="tw-w-full tw-h-full tw-min-h-[90px] md:tw-min-h-0"
								key={`sortable-image-tile-${index}`}
							>
								<SortableImageDragAndDrop
									id={index.toString()}
									image={image?.file}
									handleFile={(file: File) => handleImageAdd(file, index)}
									isSelected={selectedImageIndex === index}
									isPlaceholder={
										placeholderStartIndex
											? placeholderStartIndex <= index
											: false
									}
									handleDelete={() => handleDelete(index)}
								/>
							</div>
						))}
					</div>
				</SortableContext>
			</DndContext>
		</div>
	);
};

ImageGalleryThumbnails.displayName = 'ImageGalleryThumbnails';
