import React from 'react';
import {
	Breadcrumb,
	Dropdown,
	Col,
	Container,
	Row,
	Table,
	Form,
	Button,
} from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';
import ThreadComponent from '../../ThreadComponent';
import { WithRouter } from '../../../../helpers/Router';
import { fakeData } from '../../../factory/fakeFactory';
import URI from '../../../../defaults/RoutesDefault';
import { FormInputAdOns } from '../../../components/Form';
import { HeaderLight } from '../../../components/Header';
import InputBar from '../../../components/InputBar';
import { ActionBarVertical } from '../../../components/ActionBar';
import ListFilter from '../../../components/ListFilter';
import DatePicker from 'react-datepicker';
import { FooterFormAction } from '../../../components/Form';
import Switch from '../../../components/Switch';
import { CheckbookReconcileHeader } from 'legacy/templates/modules/accounts-payable/checkbook/CheckbookReconcile/CheckbookReconcileHeader';
class Reconcile extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			checks: {},
			totalChecked: 0,
			data: fakeData(2),
			startDate: '',
			minDate: new Date(),
			dataIsLoaded: false,
		};
	}

	componentInit() {
		this.setTitle('Checkbook').setActionBar(true);

		// Set API calls.
		this.setApiURL('/data/products.json')
			.setApiParams({ responseKey: 'response' })
			.enableSearchAndSortTable()
			.fetchData();
	}

	renderData(data) {
		this.setState({
			dataIsLoaded: true,
			data: data,
		});
	}

	handleShowTableSearch = (e) => {
		this.setState((prev) => {
			prev.showTableSearch = !prev.showTableSearch;
			return prev;
		});
	};

	handleDateChange = (date) => {
		this.setState((prevState) => (prevState.startDate = date));
	};

	handleMenuClick = (e) => {
		e.preventDefault();

		this.setState(
			(prevState) => (prevState.activeMenu = e.target.dataset.menu)
		);
	};

	handleChecks = (e) => {
		// Get the target menu.
		const indx = e.target.getAttribute('data-id');
		// Set the new state.
		this.setState((prevState) => {
			let totalChecked = 0;
			prevState.checks[indx] = e.target.checked;

			if (prevState.checks) {
				/* eslint-disable no-unused-vars */
				for (const [index, value] of Object.entries(prevState.checks)) {
					if (value) {
						totalChecked++;
					}
				}
				/* eslint-enable no-unused-vars */
			}

			prevState.totalChecked = totalChecked;

			if (totalChecked > 0) {
				document.getElementById('inline-check-th-0').checked = true;
			} else {
				document.getElementById('inline-check-th-0').checked = false;
			}

			return prevState;
		});
	};

	handleSelectAllChecks = (e) => {
		// Get the target menu.
		const isChecked = e.target.checked;

		this.setState((prevState) => {
			if (isChecked) {
				if (this.state.totalChecked < this.state.data.length) {
					this.state.data.map((item, i) => {
						prevState.checks[i] = true;
						document.getElementById(
							'chk-projectview-items-' + i
						).checked = true;
						return null;
					});
				}
			} else {
				prevState.checks = {};
				prevState.totalChecked = 0;

				const checks = document.querySelectorAll(
					'.chk-projectview-items-item input'
				);
				if (checks) {
					checks.forEach((e) => {
						e.checked = false;
					});
				}
			}

			return prevState;
		});
	};

	renderFilters() {
		return (
			<>
				<ListFilter className="checkbook-list-filter">
					<ListFilter.Fields md={12} lg={12} xl={10} className="width-25">
						<ListFilter.Field>
							<Form.Label className="text-end">
								<strong>Account</strong>
							</Form.Label>
							<Form.Select size="sm" placeholder="Select">
								<option>Select</option>
							</Form.Select>
						</ListFilter.Field>
						<ListFilter.Field className="col-lg-2">
							<Form.Label className="text-end">
								<strong>
									Statement
									<br />
									Ending Date
								</strong>
							</Form.Label>
							<div className="react-select-header">
								<DatePicker
									selected={this.state.startDate}
									minDate={this.state.minDate}
									onChange={this.handleDateChange}
									showMonthDropdown="true"
									showYearDropdown="true"
									className="form-control form-control-sm"
									placeholderText="Select"
								/>
							</div>
						</ListFilter.Field>
						<ListFilter.Field className="col-lg-2">
							<Form.Label className="text-end">
								<strong>
									Statement
									<br />
									Ending Date
								</strong>
							</Form.Label>
							<FormInputAdOns text="USD">
								<Form.Control type="text" placeholder="0.00" size="sm" />
							</FormInputAdOns>
						</ListFilter.Field>
						<Button
							as={Link}
							to={URI.accountsPayable.checkbook.list}
							variant="primary"
							size="sm"
							className="btn-icon ms-3"
						>
							Start
						</Button>
					</ListFilter.Fields>
					<ListFilter.Actions md={12} lg={12} xl={2}>
						<ListFilter.Action>
							<Button
								as={Link}
								to="#"
								variant="ivory"
								size="sm"
								className={`btn-icon btn-action ${
									this.state.showTableSearch ? 'bg-primary-ash text-white' : ''
								}`}
								onClick={this.handleShowTableSearch}
							>
								<i className="ri-search-line"></i> Search
							</Button>
						</ListFilter.Action>
						<ListFilter.Action className="ms-auto">
							<Dropdown className="d-flex justify-content-end ms-3" align="end">
								<Dropdown.Toggle
									id="dropdown-autoclose-true"
									variant="ivory"
									size="sm"
								>
									Actions
								</Dropdown.Toggle>

								<Dropdown.Menu>
									<Dropdown.Item href="">
										<i className="ri-file-transfer-line"></i> Transfer
									</Dropdown.Item>
									<Dropdown.Item href="">
										<i className="ri-eye-off-line"></i> Void
									</Dropdown.Item>
									<Dropdown.Item href="">
										<i className="ri-eye-fill"></i> Show Details
									</Dropdown.Item>
									<Dropdown.Item href="">
										<i className="ri-file-chart-line"></i> Create Report
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</ListFilter.Action>
					</ListFilter.Actions>
				</ListFilter>
				<ListFilter.Spacer />
			</>
		);
	}

	render() {
		return (
			<>
				<CheckbookReconcileHeader />

				<div className="content-padding has-action-bar">
					<Container fluid className="px-0">
						<Row>
							<Col sm="12">
								{/* Filter */}
								{this.renderFilters()}

								<div className="table-gradient">
									<Table borderless className="">
										<thead>
											<tr key="0">
												<th className="fs-lg">
													Statement Balance as
													<br />
													of 11/10/2022
												</th>
												<th className="fs-lg"></th>
												<th className="fs-lg">Deposits in Transit</th>
												<th className="fs-lg"></th>
												<th className="fs-lg">Outstanding Checks</th>
												<th className="fs-lg"></th>
												<th className="fs-lg">Balance</th>
												<th className="fs-lg">
													Computer Balance as
													<br />
													of 11/10/2022
												</th>
												<th className="fs-lg">Difference</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>
													<Form.Control
														type="text"
														placeholder="0.00"
														size="sm"
													/>
												</td>
												<td className="align-middle text-secondary-ash">
													<i className="ri-add-line ri-lg"></i>
												</td>
												<td>
													<Form.Control
														type="text"
														placeholder="0.00"
														size="sm"
													/>
												</td>
												<td className="align-middle text-secondary-ash">
													<span className="fsx-24">-</span>
												</td>
												<td>
													<Form.Control
														type="text"
														placeholder="0.00"
														size="sm"
													/>
												</td>
												<td className="align-middle text-secondary-ash">
													<span className="fsx-24">=</span>
												</td>
												<td>
													<Form.Control
														type="text"
														placeholder="0.00"
														size="sm"
													/>
												</td>
												<td>
													<Form.Control
														type="text"
														placeholder="0.00"
														size="sm"
													/>
												</td>
												<td>
													<Form.Control
														type="text"
														placeholder="0.00"
														size="sm"
													/>
												</td>
											</tr>
										</tbody>
									</Table>
								</div>
							</Col>
						</Row>

						<Row className="py-2">
							<Col
								xs={12}
								lg={6}
								className="mb-3 mb-lg-0 position-relative table-action-bar"
							>
								<div className="bg-secondary-grey py-4 mb-4 roundedx-4">
									<Row className="align-items-center px-3">
										<Col className="px-3">
											<h6 className="fw-bold mb-0">Checks</h6>
										</Col>
										<Col>
											<div className="d-flex align-items-center justify-content-end">
												<Form.Label
													className="mb-0 mx-2"
													style={{ maxWidth: '100%' }}
												>
													<strong>Suppress Cleared</strong>
												</Form.Label>
												<span>
													<Switch checked={true}></Switch>
												</span>
											</div>
										</Col>
									</Row>
								</div>

								<div className="table-gradient">
									<Table striped responsive className="a-table">
										<thead>
											<tr key="0">
												<th>Clr</th>
												<th>Sum</th>
												<th>Check #</th>
												<th>Date</th>
												<th>Amount</th>
												<th>Payee</th>
											</tr>
										</thead>
										<tbody>
											{this.state.data.map((item, i) => (
												<tr key={i}>
													<td>
														<Form.Check
															inline
															label=""
															name={`cclear-` + i}
															type="checkbox"
															data-id={i}
															id={`cclear-` + i}
															onChange={this.handleChecks}
														/>
													</td>
													<td>
														<Form.Check
															inline
															label=""
															name={`csum-` + i}
															type="checkbox"
															data-id={i}
															id={`csum-` + i}
															onChange={this.handleChecks}
														/>
													</td>
													<td>Vendor</td>
													<td>MM/DD/YYYY</td>
													<td>$300.00</td>
													<td>Lorem ipsum</td>
												</tr>
											))}
										</tbody>
									</Table>
								</div>
							</Col>

							<Col
								xs={12}
								lg={6}
								className="mb-3 mb-lg-0 position-relative table-action-bar"
							>
								<div className="bg-secondary-grey py-4 mb-4 roundedx-4">
									<Row className="align-items-center px-3">
										<Col className="px-3">
											<h6 className="fw-bold mb-0">Deposits</h6>
										</Col>
										<Col>
											<div className="d-flex align-items-center justify-content-end">
												<Form.Label
													className="mb-0 mx-2"
													style={{ maxWidth: '100%' }}
												>
													<strong>Suppress Cleared</strong>
												</Form.Label>
												<span>
													<Switch checked={true}></Switch>
												</span>
											</div>
										</Col>
									</Row>
								</div>

								<div className="table-gradient">
									<Table striped responsive className="a-table">
										<thead>
											<tr key="0">
												<th>Clr</th>
												<th>Sum</th>
												<th>Slip #</th>
												<th>Date</th>
												<th>Amount</th>
												<th>Payee</th>
											</tr>
										</thead>
										<tbody>
											{this.state.data.map((item, i) => (
												<tr key={i}>
													<td>
														<Form.Check
															inline
															label=""
															name={`dclear-` + i}
															type="checkbox"
															data-id={i}
															id={`dclear-` + i}
															onChange={this.handleChecks}
														/>
													</td>
													<td>
														<Form.Check
															inline
															label=""
															name={`dsum-` + i}
															type="checkbox"
															data-id={i}
															id={`dsum-` + i}
															onChange={this.handleChecks}
														/>
													</td>
													<td>Vendor</td>
													<td>MM/DD/YYYY</td>
													<td>$300.00</td>
													<td>Lorem ipsum</td>
												</tr>
											))}
										</tbody>
									</Table>
								</div>
							</Col>
						</Row>

						<Row>
							<Col xs={12} lg={6} className="mb-3 mb-lg-0">
								<div className="bg-secondary-grey py-4 mb-4 roundedx-4">
									<Row className="align-items-center px-3">
										<Col className="px-3">
											<h6 className="fw-bold mb-0">Check Totals</h6>
										</Col>
									</Row>
								</div>

								<InputBar className="full multiple bg-white border-0">
									<InputBar.Links style={{ maxWidth: 'none' }} className="px-0">
										<InputBar.Link>
											<Form.Label htmlFor="inputPassword5" className="ilabel">
												<strong>Sum</strong>
											</Form.Label>
											<FormInputAdOns text="USD">
												<Form.Control
													type="text"
													placeholder="0.00"
													size="sm"
												/>
											</FormInputAdOns>
										</InputBar.Link>
										<InputBar.Link>
											<Form.Label htmlFor="inputPassword5" className="ilabel">
												<strong>Cleared Checks</strong>
											</Form.Label>
											<FormInputAdOns text="USD">
												<Form.Control
													type="text"
													placeholder="0.00"
													size="sm"
												/>
											</FormInputAdOns>
										</InputBar.Link>
									</InputBar.Links>
								</InputBar>
								<InputBar className="full multiple bg-white border-0 w-100">
									<InputBar.Links
										style={{ maxWidth: 'none' }}
										className="ms-auto px-0"
									>
										<InputBar.Link>
											<Form.Label htmlFor="inputPassword5" className="ilabel">
												<strong>Service Checks</strong>
											</Form.Label>
											<FormInputAdOns text="USD">
												<Form.Control
													style={{
														maxWidth: '128px',
													}}
													type="text"
													placeholder="0.00"
													size="sm"
												/>
											</FormInputAdOns>
										</InputBar.Link>
										<Button
											variant="ivory"
											size="sm"
											className="btn-icon px-0"
											style={{
												width: '30px',
												minWidth: 'auto',
											}}
										>
											<i className="ri-pencil-line"></i>
										</Button>
									</InputBar.Links>
								</InputBar>
							</Col>
							<Col xs={12} lg={6} className="mb-3 mb-lg-0">
								<div className="bg-secondary-grey py-4 mb-4 roundedx-4">
									<Row className="align-items-center px-3">
										<Col className="px-3">
											<h6 className="fw-bold mb-0">Check Totals</h6>
										</Col>
									</Row>
								</div>

								<InputBar className="full multiple bg-white border-0">
									<InputBar.Links style={{ maxWidth: 'none' }} className="px-0">
										<InputBar.Link>
											<Form.Label htmlFor="inputPassword5" className="ilabel">
												<strong>Sum</strong>
											</Form.Label>
											<FormInputAdOns text="USD">
												<Form.Control
													type="text"
													placeholder="0.00"
													size="sm"
												/>
											</FormInputAdOns>
										</InputBar.Link>
										<InputBar.Link>
											<Form.Label htmlFor="inputPassword5" className="ilabel">
												<strong>Cleared Checks</strong>
											</Form.Label>
											<FormInputAdOns text="USD">
												<Form.Control
													type="text"
													placeholder="0.00"
													size="sm"
												/>
											</FormInputAdOns>
										</InputBar.Link>
									</InputBar.Links>
								</InputBar>
							</Col>
						</Row>
					</Container>
				</div>

				<FooterFormAction>
					<Button variant="primary" size="lg">
						Reconcile
					</Button>
				</FooterFormAction>
			</>
		);
	}
}

export default WithRouter(Reconcile);
