import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
	Breadcrumb,
	Container,
	Button,
	Form,
	Row,
	Col,
	Table,
} from 'react-bootstrap';
import ThreadComponent from '../../ThreadComponent';
import Header from '../../../components/Header';
import URI from '../../../../defaults/RoutesDefault';
import { WithRouter } from '../../../../helpers/Router';
import { ApiService } from '../../../../lib/api/HttpService';
import { pathParam } from '../../../../utilities/Router';
import { displayAlert } from '../../../../utilities/Response';
import ConfirmModal from '../../../../app/components/modal/ConfirmModal';
import { showLoading } from '../../../../helpers/Loading';
import { tableSortingEnableSort } from '../../../../utilities/modules/TableSorting';
import FilterSort from '../../../../utilities/modules/FilterSort';

class UserList extends ThreadComponent {
	constructor(props) {
		super(props);

		this.fs = new FilterSort('user_management_list');
		this.state = {
			data: [],
			dataIsLoaded: false,
			invitedUsers: [],
			disabledDeleteButton: true,
			checks: {},
			totalChecked: 0,
			showModal: false,
			showTableSearch: this.fs.isSearchActive(),
			searchProperties: this.fs.getSearches(),
		};

		this.errIds = [];
		this.api = new ApiService();
	}

	componentInit() {
		this.setTitle('User Management');
	}

	componentDidMount() {
		this.fetchItems();
	}

	async fetchItems() {
		const users = await this.api.get('manageaccount', '?$orderby=fullName');

		this.setState(
			{
				data: users.users,
				invitedUsers: users.invitedUsers,
				dataIsLoaded: true,
			},
			() => {
				this.enableTableSortPendingUsers(users.invitedUsers);
				this.enableTableSortActiveUsers(users.users);
			}
		);
	}

	renderData(data) {
		this.setState({
			dataIsLoaded: true,
			data: data,
		});
	}

	handleShowTableSearch = (e) => {
		this.setState((prev) => {
			const data = {};
			data.showTableSearch = !prev.showTableSearch;
			this.fs.setIsActiveSearch(!prev.showTableSearch);
			return data;
		});
	};

	handleResetPassword = (e) => {
		const username = e.target.attributes['data-username'].value;
		const userData = this.state.activeUsers.find(
			(u) => u.username === username
		);
		const param = {
			username: username,
			email: userData.email,
		};

		this.api.postResetPasswordInitiate(param).then((resp) => {
			displayAlert('success', 'Successfully initiated reset password');
		});
	};

	handleChecks = (e) => {
		// Get the target menu.
		const indx = e.target.getAttribute('data-id');
		// Set the new state.
		this.setState((prevState) => {
			let totalChecked = 0;
			prevState.checks[indx] = e.target.checked;

			if (prevState.checks) {
				/* eslint-disable no-unused-vars */
				for (const [index, value] of Object.entries(prevState.checks)) {
					if (value) {
						totalChecked++;
					}
				}
				/* eslint-enable no-unused-vars */
			}

			prevState.totalChecked = totalChecked;

			if (totalChecked > 0) {
				document.getElementById('inline-check-th-0').checked = true;
			} else {
				document.getElementById('inline-check-th-0').checked = false;
			}

			prevState.disabledDeleteButton = prevState.totalChecked <= 0;
			return prevState;
		});
	};

	handleSelectAllChecks = (e) => {
		// Get the target menu.
		const isChecked = e.target.checked;

		this.setState((prevState) => {
			if (isChecked) {
				if (this.state.totalChecked < this.state.invitedUsers.length) {
					this.state.invitedUsers.map((item, i) => {
						prevState.checks[item.userId] = true;
						document.getElementById(
							'chk-projectview-items-' + i
						).checked = true;
						return null;
					});
				}
				prevState.totalChecked = this.state.invitedUsers.length;
			} else {
				prevState.checks = {};
				prevState.totalChecked = 0;

				const checks = document.querySelectorAll(
					'.chk-projectview-items-item input'
				);
				if (checks) {
					checks.forEach((e) => {
						e.checked = false;
					});
				}
			}

			prevState.disabledDeleteButton = prevState.totalChecked <= 0;
			return prevState;
		});
	};

	toggleModal = (e) => {
		this.setState({
			showModal: !this.state.showModal,
		});
	};

	uncheckAll() {
		const checks = document.querySelectorAll(
			'.chk-projectview-items-item input'
		);
		if (checks) {
			checks.forEach((e) => {
				e.checked = false;
			});
		}
	}

	// Refresh data
	refreshDataAfterDelete = (e) => {
		const headingCheck = document.getElementById('inline-check-th-0');

		this.setState({
			checks: {},
			totalChecked: 0,
			showModal: false,
		});

		headingCheck.checked = false;
		this.errIds = [];

		this.fetchItems();

		this.uncheckAll();
	};

	async handleDelete(id) {
		await this.api
			.deleteUserInvitation(id)
			.then((res) => {
				if (res.status !== 200) this.errIds.push(id);
			})
			.catch((error) => {
				this.errIds.push(id);
			});
	}

	async deleteData(e) {
		for (const [index, value] of Object.entries(this.state.checks)) {
			if (value) {
				await this.handleDelete(index);
			}
		}

		const message = this.errIds.length
			? 'The data with the ids [' + this.errIds.join() + "] couldn't be deleted"
			: 'The data was succesfully deleted';
		const variant = this.errIds.length ? 'danger' : 'success';

		displayAlert(variant, message);

		this.refreshDataAfterDelete(e);
	}

	enableTableSortPendingUsers(data) {
		tableSortingEnableSort({
			data,
			stateKey: 'invitedUsers',
			classRef: this,
			targetTable: '.a-table-heading.pending',
		});
	}

	enableTableSortActiveUsers(data) {
		tableSortingEnableSort({
			data,
			stateKey: 'data',
			classRef: this,
			targetTable: '.a-table-heading.active',
		});
	}

	renderHeader() {
		return (
			<Header buttons={1}>
				<Header.TitleContent>
					<Header.LeftContent>
						<Header.Breadcumbs>
							<NavLink
								to={URI.settings.list}
								className="text-white active d-flex align-items-center text-decoration-none fw-bold me-4 mb-3"
							>
								<i className="ri-arrow-left-s-line"></i> Back
							</NavLink>
							<Breadcrumb className="breadcrumb-light">
								<Breadcrumb.Item
									linkProps={{ to: URI.settings.list }}
									linkAs={Link}
								>
									Settings
								</Breadcrumb.Item>
								<Breadcrumb.Item>User Management</Breadcrumb.Item>
							</Breadcrumb>
						</Header.Breadcumbs>
						<Header.Title>User Management</Header.Title>
					</Header.LeftContent>
					<Header.Actions list={true}>
						<li>
							<Button
								as={Link}
								to={URI.settings.userManagement.add}
								variant="light"
								size="lg"
								className="btn-icon text-secondary-green"
							>
								<i className="ri-add-line ri-lg"></i> Add
							</Button>
						</li>
					</Header.Actions>
				</Header.TitleContent>
			</Header>
		);
	}

	renderInvitedUsers() {
		return (
			<Form.Group>
				<Row xs={1}>
					<Col>
						<div className="bg-secondary-grey roundedx-4 mb-4 py-3">
							<Row className="align-items-center px-4">
								<Col className="px-3">
									<h6 className="fw-bold mb-0">Pending Invitations</h6>
								</Col>
								<Col className="px-3 justify-content-end d-flex">
									<Button
										variant="primary"
										size="sm"
										className={
											this.state.disabledDeleteButton ||
											this.state.totalChecked < 1
												? 'btn-icon ms-3 disabled'
												: 'btn-icon ms-3'
										}
										onClick={this.toggleModal.bind(this)}
									>
										Delete
									</Button>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>

				<Row xs={1} className="mb-5">
					<Col>
						<div className="table-gradient sticky-container">
							<Table striped responsive className="a-table">
								<thead>
									<tr className="a-table-heading pending">
										<th align="middle" className="w-70px">
											<div className="d-flex justify-content-center">
												<Form.Check
													inline
													label=""
													name={`inline-check-th-0`}
													type="checkbox"
													data-id={`th-0`}
													id={`inline-check-th-0`}
													defaultChecked={
														this.state.totalChecked > 0 ? true : false
													}
													className={`${
														this.state.totalChecked > 0 &&
														this.state.totalChecked <
															this.state.invitedUsers.length
															? 'line'
															: ''
													}`}
													onClick={this.handleSelectAllChecks}
												/>
											</div>
										</th>
										<th>
											<span className="sort" data-field="fullName">
												Name
											</span>
										</th>
										<th>
											<span className="sort" data-field="email">
												Email
											</span>
										</th>
									</tr>
									<tr
										className={`a-table-search-fields ${
											this.state.showTableSearch ? '' : 'd-none'
										}`}
									>
										<th>
											<Form.Control type="text" data-field="password" />
										</th>
										<th>
											<Form.Control type="text" data-field="useremail" />
										</th>
										<th></th>
										<th></th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{(() => {
										if (this.state.dataIsLoaded) {
											if (this.state.invitedUsers.length) {
												return this.state.invitedUsers.map((item, i) => (
													<tr key={i} data-key={i}>
														<td>
															<div className="d-flex justify-content-center">
																<Form.Check
																	label=""
																	name={`group-` + i}
																	type="checkbox"
																	data-id={item.userId}
																	id={`chk-projectview-items-` + i}
																	className="chk-projectview-items-item"
																	defaultValue={i}
																	onClick={this.handleChecks}
																	defaultChecked={
																		this.state.checks[i] ? true : false
																	}
																/>
															</div>
														</td>
														<td>{item.fullName}</td>
														<td>{item.email}</td>
													</tr>
												));
											} else {
												return (
													<tr>
														<td colSpan={12} className="fw-bold">
															No record found.
														</td>
													</tr>
												);
											}
										} else {
											return showLoading();
										}
									})()}
								</tbody>
							</Table>
						</div>
					</Col>
				</Row>
			</Form.Group>
		);
	}

	renderContent() {
		return (
			<Form.Group>
				<Row xs={1}>
					<Col>
						<div className="bg-secondary-grey roundedx-4 mb-4 py-3">
							<Row className="align-items-center px-4">
								<Col className="px-3">
									<h6 className="fw-bold mb-0">Users</h6>
								</Col>
								<Col className="px-3 justify-content-end d-flex">
									<Button
										as={Link}
										to="#"
										variant="ivory"
										size="sm"
										className={`btn-icon btn-action ${
											this.state.showTableSearch
												? 'bg-primary-ash text-white'
												: ''
										}`}
										onClick={this.handleShowTableSearch}
									>
										<i className="ri-search-line"></i> Search
									</Button>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>

				<Row xs={1} className="mb-5">
					<Col>
						<div className="table-gradient sticky-container">
							<Table striped responsive className="a-table">
								<thead>
									<tr className="a-table-heading active">
										<th>
											<span className="sort" data-field="username">
												Username
											</span>
										</th>
										<th>
											<span className="sort" data-field="email">
												Email
											</span>
										</th>
										<th>
											<span className="sort" data-field="disabled">
												Enabled
											</span>
										</th>
										<th>
											<span className="sort" data-field="isAdmin">
												Account Manager
											</span>
										</th>
										{/*<th>*/}
										{/*    <span className="sort">*/}
										{/*        Password*/}
										{/*    </span>*/}
										{/*</th>*/}
									</tr>
									<tr
										className={`a-table-search-fields ${
											this.state.showTableSearch ? '' : 'd-none'
										}`}
									>
										<th>
											<Form.Control type="text" data-field="password" />
										</th>
										<th>
											<Form.Control type="text" data-field="useremail" />
										</th>
										<th></th>
										<th></th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{(() => {
										if (this.state.dataIsLoaded) {
											return this.state.data.map((item, i) => (
												<tr key={i} data-key={i}>
													<td>
														<Link
															to={pathParam(URI.settings.userManagement.edit, {
																id: item.username,
															})}
															className="text-charcoal hover-view-icon"
														>
															{item.username}
														</Link>
													</td>
													<td>{item.email}</td>
													<td>
														<strong
															className={
																item.disabled
																	? `dot-primary-red`
																	: `dot-primary`
															}
														></strong>
													</td>
													<td>
														<strong
															className={
																item.isAdmin ? `dot-primary` : `dot-primary-red`
															}
														></strong>
													</td>
													{/*<td>*/}
													{/*    <Link*/}
													{/*        data-username={*/}
													{/*            item.username*/}
													{/*        }*/}
													{/*        onClick={*/}
													{/*            this*/}
													{/*                .handleResetPassword*/}
													{/*        }*/}
													{/*    >*/}
													{/*        Reset Password*/}
													{/*    </Link>*/}
													{/*</td>*/}
												</tr>
											));
										} else {
											return showLoading();
										}
									})()}
								</tbody>
							</Table>
						</div>

						<Row>
							<Col lg={6}>
								<p className="fs-sm">
									* Adding a user will incur a charge. These charges will be
									added into your next billing cycle. For more information, see{' '}
									<NavLink
										target="_blank"
										to={'https://www.designmanager.com/pricing'}
										className="text-charcoal"
									>
										Pricing
									</NavLink>
									.
								</p>
							</Col>
						</Row>
					</Col>
				</Row>
			</Form.Group>
		);
	}

	render() {
		return (
			<>
				{this.renderHeader()}

				<div className="content-padding min-height has-action-bar">
					<Container fluid>
						{this.renderInvitedUsers()}
						{this.renderContent()}
					</Container>
					<ConfirmModal
						refreshData={this.refreshDataAfterDelete.bind(this)}
						show={this.state.showModal}
						toggleModal={this.toggleModal}
						confirmAction={this.deleteData.bind(this)}
					/>
				</div>
			</>
		);
	}
}

export default WithRouter(UserList);
