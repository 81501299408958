import { ApiService } from 'legacy/lib/api/HttpService';
import { TCompany2 } from 'legacy/lib/api/types/Company2';

const api = new ApiService();

// Need to do a require because this module doesn´t have any types
/* eslint-disable @typescript-eslint/no-var-requires */
const HtmlToRtfBrowser = require('html-to-rtf-browser').default;

const getItemOrMainComponentDescription = async (
	name = '',
	{
		value = '',
		html = '',
	}: {
		value: string;
		html: string;
	}
) => {
	const company2 = (await api.get('company2')) as TCompany2;
	const itemNameFormatted =
		company2.boldItemName && name ? '{\\b ' + name + ' }\\sb70' : name ?? '';
	const formatter = new HtmlToRtfBrowser();
	const descOnly = value;
	const descOnlyRtf = formatter
		.convertHtmlToRtf(html || '')
		.replaceAll('\\~', ' ');

	const descrtf = formatter.convertHtmlToRtf(
		`${itemNameFormatted ?? ''} <br> ${html ?? ''}`
	);

	const desc = `${name ?? ''} ${value ?? ''}`;

	return { descOnly, descOnlyRtf, desc, descrtf };
};

export default getItemOrMainComponentDescription;
