import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { ApiService } from 'legacy/lib/api/HttpService';
import { Summary } from 'legacy/lib/api/types/Summary';
import { uniqBy } from 'lodash';
import BaseDropdown, { TBaseDropdownProps } from './BaseDropdown';

const api = new ApiService();

const getAddresses = async (filterAddressesForItem?: boolean) => {
	const addresses = (await api.getAddressesSummaries(
		filterAddressesForItem
			? '?$filter=addresstype eq 1 and inactive eq false &$orderby=attention asc'
			: ''
	)) as Summary[];
	const uniqueAddresses = uniqBy(addresses, (obj) => obj.key);
	return uniqueAddresses;
};

const AddressesDropdown = (
	props: TBaseDropdownProps & {
		filterAddressesForItem?: boolean;
	}
) => {
	const { data, isLoading } = useQuery({
		queryKey: ['dropdowns', 'addressesSummaries', props.filterAddressesForItem],
		queryFn: () => getAddresses(props.filterAddressesForItem),
		refetchOnWindowFocus: false,
		refetchInterval: false,
	});

	const options =
		data?.map((option) => ({
			label: `${option.value ?? ''} ${
				option.key ? `[${option.key}]` : ''
			}`.trim(),
			value: option.key,
		})) || [];

	return <BaseDropdown {...props} isLoading={isLoading} options={options} />;
};

AddressesDropdown.displayName = 'AddressesDropdown';

export default AddressesDropdown;
