import React from 'react';
import { generatePath, Link, NavLink } from 'react-router-dom';
import { Breadcrumb, Container, Button } from 'react-bootstrap';
import ThreadComponent from '../ThreadComponent';
import { WithRouter } from '../../../helpers/Router';
import { StringCompare, titleCase } from '../../../helpers/String';
import ProjectAddInfo from './ProjectAddInfo';
import ProjectAddDefaults from './ProjectAddDefaults';
import ProjectAddNotes from './ProjectAddNotes';
import ProjectAddBudget from './ProjectAddBudget';
import { FooterFormAction } from '../../components/Form';
import { HeaderLight } from '../../components/Header';
import URI from '../../../defaults/RoutesDefault';
import {
	displayAlert,
	displayAlertError,
	displayAlertLoader,
	getErrorMessage,
} from '../../../utilities/Response';
import { ApiService } from '../../../lib/api/HttpService';
import ProjectAddSettings from './ProjectAddSettings';
import { setDraft } from '../../../utilities/FormEvent';
import Spinner from '../../../app/components/help/Spinner';
import MSG from '../../../defaults/Message';

class ProjectAdd extends ThreadComponent {
	constructor(props) {
		super(props);
		this.api = new ApiService();

		this.state = {
			activeMenu: 'info',
			data: {},
			dataIsLoaded: false,
		};

		this.str = new StringCompare(this.state.activeMenu)
			.set('trueValue', 'active')
			.set('falseValue', '');

		this.handleMenuClick = this.handleMenuClick.bind(this);
	}

	componentInit() {
		this.updatedData = {
			billingAddress: {},
			siteAddress: {},
			sitesameasbill: true,
			selectedClientInvoice: null,
			timebillingtier: 0,
		};
		this.uploadedImage = null;

		this.setTitle(`Add Project`);
	}

	async componentDidMount() {
		const company1 = await this.api.get('company', '');
		const company2 = await this.api.get('company2', '');
		const company = { ...company1, ...company2 };
		company.propotherdesc = company.propothersdesc;
		company.propotherstyle = company.propothersstyle;
		company.invotherdesc = company.invothersdesc;
		company.invotherstyle = company.invothersstyle;
		delete company.id;
		delete company.propothersdesc;
		delete company.propothersstyle;
		delete company.invothersdesc;
		delete company.invothersstyle;

		this.updatedData = {
			...this.updatedData,
			...company,
		};

		let data = await this.getAdditionalData({ taxc: 'OOS' });
		this.renderData(data);
	}

	async getAdditionalData(data) {
		data = await this.addSalesTaxCodesData(data);
		data = await this.addManagersData(data);
		data = await this.addBudgetData(data);

		return data;
	}

	async addBudgetData(data) {
		data.budget = data.hasOwnProperty('proj')
			? await this.api.get('budgets', `?$filter=proj eq '${data.proj}'`)
			: [];

		return data;
	}

	async addSalesTaxCodesData(data) {
		const taxCode = await this.api.getSalesTaxCodeByCode(data?.taxc);
		await this.fetchTaxables(data?.taxc);
		data.selectedTaxCode = taxCode;

		return data;
	}

	async fetchTaxables(taxc) {
		if (!taxc) {
			return;
		}

		const taxCode = await this.api.getSalesTaxCodeByCode(taxc);
		this.updatedData = {
			...this.updatedData,
			taxd: taxCode.td ?? false,
			taxf: taxCode.tf ?? false,
			taxi: taxCode.ti ?? false,
			taxl: taxCode.tl ?? false,
			taxm: taxCode.tm ?? false,
			taxo: taxCode.to ?? false,
		};
	}

	async addManagersData(data) {
		const employeeData = await this.api.getClientEmployees();

		data.managers = [];
		for (let emp of employeeData) {
			data.managers.push({
				value: emp.vendor,
				label: emp.vendorn,
				designer: emp.designer,
			});
			//if (client.length > 0 && emp.vendor === client.manager) client.managern = emp.vendorn;
		}

		return data;
	}

	handleDataChange = async (data) => {
		if (data.hasOwnProperty('billingAddress')) {
			this.updatedData = {
				...this.updatedData,
				...data,
			};
		} else {
			const { value = '' } = data;
			if (data.key.indexOf('.') > -1) {
				const keys = data.key.split('.');
				if (!this.updatedData.hasOwnProperty(keys[0]))
					this.updatedData[keys[0]] = {};
				this.updatedData[keys[0]][keys[1]] = value;
			} else {
				this.updatedData[data.key] = value;
			}

			if (data.key === 'sitesameasbill') {
				if (!this.updatedData.hasOwnProperty('billingAddress'))
					this.updatedData.billingAddress = {};

				this.updatedData.siteAddress = {
					...this.updatedData.billingAddress,
				};
				if (this.updatedData.siteAddress.hasOwnProperty('website'))
					delete this.updatedData.siteAddress.website;
			} else if (data.key === 'taxc') {
				await this.fetchTaxables(data.value);
			}
		}
	};

	renderData(data) {
		this.setState({
			dataIsLoaded: true,
			data: data ?? {},
		});
	}

	handleSaveClick = async (e) => {
		e.preventDefault();

		if (this.updatedData.taxc) {
			const tx = await this.api.getSalesTaxCodeByCode(
				this.updatedData.taxc?.value ?? this.updatedData.taxc
			);

			if (tx.inactive) {
				displayAlert(
					'danger',
					'Project cannot be created due to selected sales tax code is inactive'
				);
				return;
			}
		}

		if (
			!this.updatedData.hasOwnProperty('proj') ||
			!this.updatedData.hasOwnProperty('dateopened') ||
			!this.updatedData.hasOwnProperty('client') ||
			!this.updatedData.hasOwnProperty('projn')
		) {
			displayAlert('danger', 'All fields marked with * must be filled out.');
		} else {
			try {
				if (this.updatedData.taxc?.label) {
					this.updatedData.taxc = this.updatedData.taxc.value;
				}
				displayAlertLoader(MSG.loading.create.Project);
				let projectData = JSON.parse(JSON.stringify(this.updatedData));
				delete projectData.billingAddress;
				delete projectData.siteAddress;

				if (!projectData.hasOwnProperty('clientorder')) {
					projectData.clientorder = '';
				}

				projectData.shipto = projectData.useSite ? null : projectData.shipto;
				delete projectData.useSite;

				const project = await this.api.postJson('projects', projectData);

				this.updatedData.billingAddress.code = this.updatedData.proj;
				this.updatedData.billingAddress.addresstype = 0;
				this.updatedData.billingAddress.addressTypeEnum = 'ProjectBilling';

				await this.api.createAddress(this.updatedData.billingAddress);

				this.updatedData.siteAddress.code = this.updatedData.proj;
				this.updatedData.siteAddress.addresstype = 6;
				this.updatedData.siteAddress.addressTypeEnum = 'ProjectSite';

				if (!this.updatedData.sitesameasbill) {
					await this.api.createAddress(this.updatedData.siteAddress);
				}

				displayAlert('success', MSG.success.create.Project);
				setTimeout(() => {
					window.location.href = generatePath(
						e.target.name === 'savenew'
							? URI.project.add
							: `/projects/${project.id}/items`
					);
				}, 1500);

				setDraft(false);
			} catch (error) {
				displayAlertError(getErrorMessage(error, MSG.error.create.Project));
			}
		}
	};

	handleMenuClick = (e) => {
		e.preventDefault();

		this.setState(
			(prevState) =>
				(prevState.activeMenu = this.str.theString =
					e.target.getAttribute('data-menu'))
		);
	};

	handleClientCreate = async (client) => {
		let data = this.state.data;
		data = await this.getAdditionalData(data);

		// Set default and selected data.
		data.client = client.client;
		this.updatedData.client = client.client;

		this.renderData(data);
	};

	renderHeader() {
		const { activeMenu } = this.state;
		const { data } = this.state;
		return (
			<HeaderLight>
				<HeaderLight.Breadcumbs>
					<NavLink
						to={URI.project.base}
						className="text-primary active d-flex align-items-center text-decoration-none fw-bold"
					>
						<i className="ri-arrow-left-s-line"></i> Back
					</NavLink>
					<Breadcrumb className="ms-4">
						<Breadcrumb.Item linkProps={{ to: URI.project.base }} linkAs={Link}>
							Projects
						</Breadcrumb.Item>
						<Breadcrumb.Item linkProps={{ to: URI.project.add }} linkAs={Link}>
							Add Project
						</Breadcrumb.Item>
						<Breadcrumb.Item>
							{titleCase(this.state.activeMenu)}
						</Breadcrumb.Item>
					</Breadcrumb>
				</HeaderLight.Breadcumbs>
				<HeaderLight.Content actions={true} className="flex-column flex-sm-row">
					<HeaderLight.Title>Add Projects</HeaderLight.Title>

					<div>
						<Button
							as={Link}
							variant="ivory"
							name="cancel"
							className="mx-3"
							to={URI.project.base}
						>
							Cancel
						</Button>
						{/* Submit Button */}
						<Button
							onClick={this.handleSaveClick.bind(this)}
							name="savenew"
							variant="primary me-2"
						>
							Save & New
						</Button>

						<Button
							onClick={this.handleSaveClick.bind(this)}
							name="save"
							variant="primary"
						>
							Save
						</Button>
					</div>
				</HeaderLight.Content>
				<HeaderLight.Actions className="text-charcoal">
					<li>
						<Button
							as={Link}
							to={URI.project.add}
							data-menu="info"
							data-testid="project-add-info-tab"
							className={`${this.str.compareReturn('info')}`}
							onClick={this.handleMenuClick}
						>
							Info
						</Button>
					</li>
					<li>
						<Button
							as={Link}
							to={URI.project.add}
							data-menu="settings"
							data-testid="project-add-settings-tab"
							className={`${this.str.compareReturn('settings')}`}
							onClick={this.handleMenuClick}
						>
							Settings
						</Button>
					</li>
					{this.props.params.id && (
						<li>
							<Button
								as={Link}
								to={URI.project.add}
								data-menu="budget"
								className={`${this.str.compareReturn('budget')}`}
								onClick={this.handleMenuClick}
							>
								Budget
							</Button>
						</li>
					)}
				</HeaderLight.Actions>
			</HeaderLight>
		);
	}

	renderContent() {
		const { data } = this.state;
		if (this.str.compare('info'))
			return (
				<ProjectAddInfo
					data={data}
					updatedData={this.updatedData}
					onDataChange={this.handleDataChange}
					onClientCreate={this.handleClientCreate.bind(this)}
				/>
			);

		if (this.str.compare('settings'))
			return (
				<ProjectAddSettings
					data={data}
					updatedData={this.updatedData}
					onDataChange={this.handleDataChange}
				/>
			);

		if (this.str.compare('budget'))
			return (
				<ProjectAddBudget
					data={data}
					updatedData={this.updatedData}
					onDataChange={this.handleDataChange}
				/>
			);
	}

	render() {
		if (this.state.dataIsLoaded) {
			return (
				<>
					{/* Header */}
					{this.renderHeader()}

					{/* Content */}
					<div className="content-padding min-height">
						<Container fluid>{this.renderContent()}</Container>
					</div>

					{/* Submit Button */}
					<FooterFormAction>
						<Button
							className="mx-auto mx-md-0"
							variant="primary"
							size="lg"
							onClick={this.handleSaveClick}
							name="save"
							data-testid="project-add-save-button"
						>
							Save
						</Button>
					</FooterFormAction>
				</>
			);
		} else {
			return (
				<>
					<Spinner />
				</>
			);
		}
	}
}

export default WithRouter(ProjectAdd);
