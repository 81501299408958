import { FeatureFlags } from 'legacy/app/enums/featureFlags/featureFlags';
import URI from 'legacy/defaults/RoutesDefault';
import { useHeader } from 'legacy/hooks/useHeader';
import React from 'react';
import { useFeature } from 'use-feature';

export const CheckbookOverviewHeader = () => {
	const showFeature = useFeature(FeatureFlags.CheckbookReconcile);

	useHeader({
		title: 'Checkbook',
		breadcrumbs: [
			{
				text: 'Accounts Payable',
				to: URI.accountsPayable.creditCard.list,
			},
			{
				text: 'Checkbook',
			},
		],
		tabs: [
			{
				label: 'Checkbook Overview',
				to: URI.accountsPayable.checkbook.base,
				active: true,
				id: 'checkbook',
			},
			...(showFeature
				? [
						{
							label: 'Reconcile',
							to: URI.accountsPayable.checkbook.reconcile,
							active: false,
							id: 'reconcile',
						},
				  ]
				: []),
			...(showFeature
				? [
						{
							label: 'Reconcile History',
							to: URI.accountsPayable.checkbook.history,
							active: false,
							id: 'reconcile-history',
						},
				  ]
				: []),
		],
		enableBackButton: false,
	});
	return <></>;
};

CheckbookOverviewHeader.displayName = 'CheckbookOverviewHeader';
