import { Controller } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';

export default function CurrencyInput({
	control,
	name,
	rules,
	defaultValue,
	...props
}) {
	return (
		<Controller
			control={control}
			name={name}
			rules={rules}
			render={({ field: { onChange, value } }) => (
				<NumericFormat
					{...(defaultValue !== undefined ? { defaultValue } : {})}
					customInput={Form.Control}
					thousandSeparator={true}
					prefix={'$'}
					onValueChange={(e) => onChange(Number(e.value))}
					value={value}
					decimalScale={2}
					fixedDecimalScale={true}
					{...props}
				/>
			)}
		/>
	);
}
