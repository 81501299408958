/**
 * Converts a date string YYYY-MM-DD format to correct date format MM-DD-YYYY
 *
 * @param {string} date - Date string in format YYYY-MM-DD
 * @returns {string} Formatted date string in MM/DD/YYYY format
 */
export function formatDate(date) {
	if (date) {
		const formattedDate = stringToDate(date);

		return formattedDate.toLocaleDateString('en-US', {
			month: '2-digit',
			day: '2-digit',
			year: 'numeric',
		});
	}
}

/**
 * Converts a date string of YYYY-MM-DD format to a Date object
 *
 * @param {string} dateString - Date string in format YYYY-MM-DD
 * @returns {Date} Date object representing the input date
 */
export function stringToDate(dateString) {
	if (!dateString || isInvalidDate(dateString)) {
		return '';
	}

	const datePattern = /^\d{4}-\d{2}-\d{2}$/;

	if (datePattern.test(dateString)) {
		const [year, month, day] = dateString.split('-');
		return new Date(Number(year), Number(month) - 1, Number(day));
	}

	return new Date(dateString);
}

/**
 * Formats a date string to a locale-specific date format.
 *
 * @param {string} date - Date string
 * @returns {string} Formatted locale-specific date string
 */
export function localeDate(date) {
	if (date) {
		const formattedDate = stringToDate(date);
		return formattedDate.toLocaleDateString('en-US');
	}
}

/**
 * Formats a date string to a date and time string with locale-specific format.
 *
 * @param {string} date - Date string
 * @returns {string} Formatted date and time string
 */
export function formatDateTime(date) {
	if (date) {
		const formattedDate = stringToDate(date);

		return formattedDate
			.toLocaleDateString('en-US', {
				month: '2-digit',
				day: '2-digit',
				year: 'numeric',
				hour: '2-digit',
				hour12: true,
				minute: '2-digit',
			})
			.replace(/,/gi, '');
	}
}

/**
 * Formats a date string to a fiscal month date format (MM/YYYY).
 *
 * @param {string} date - Date string
 * @returns {string} Formatted fiscal month date string
 */
export function formatFiscalMonth(date) {
	if (date) {
		const formattedDate = stringToDate(date);
		return formattedDate.toLocaleDateString('en-US', {
			month: '2-digit',
			year: 'numeric',
		});
	}
}

/**
 * Formats a date string to a filter date format (YYYY-MM-DD).
 *
 * @param {string} date - Date string
 * @returns {string} Formatted filter date string
 */
export function formatFilterDate(date) {
	let formattedDate = stringToDate(date);
	if (formattedDate === '') {
		return '';
	}

	const day = formattedDate.getDate();
	const month = formattedDate.getMonth() + 1;
	const year = formattedDate.getFullYear();

	return `${year}-${month}-${day}`;
}

/**
 * Formats a date string to a filter month date format (YYYY-MM-01).
 *
 * @param {string} date - Date string
 * @returns {string} Formatted filter month date string
 */
export function formatFilterMonth(date) {
	let formattedDate = stringToDate(date);
	if (formattedDate === '') {
		return '';
	}
	formattedDate = formattedDate.toLocaleDateString('en-US', {
		month: '2-digit',
		year: 'numeric',
	});

	const parts = formattedDate.split('/');
	const year = parts[1];
	const month = parts[0].padStart(2, '0');
	return `${year}-${month}-01`;
}

/**
 * Checks if a date string is a valid date.
 *
 * @param {string} dateString - Date string
 * @returns {boolean} True if the date is valid, otherwise false
 */
function isInvalidDate(dateString) {
	const date = new Date(dateString);
	return isNaN(date.getTime());
}

/**
 * Converts a date to a payload format (ISO 8601).
 *
 * @param {string|Date} inputDate - Date string or Date object
 * @returns {string} Formatted date string in ISO 8601 format
 */
export function dateToPayload(inputDate) {
	if (!inputDate) {
		return null;
	}

	if (typeof inputDate === 'string') {
		inputDate = new Date(inputDate);
		if (isNaN(inputDate)) {
			return null;
		}
	}

	let month = adjust(inputDate.getMonth() + 1);
	let day = adjust(inputDate.getDate());
	const year = inputDate.getFullYear();

	return `${year}-${month}-${day}T00:00:00.000Z`;

	function adjust(number) {
		return number < 10 ? `0${number}` : number;
	}
}
