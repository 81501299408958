import { UseQueryOptions, useQueries } from '@tanstack/react-query';
import { ApiService } from '../HttpService';
import { useEffect } from 'react';
import { displayAlertError } from 'legacy/utilities/Response';

const api = new ApiService();

const getFile = async (id: string) => (await api.getFile(id)) as Blob;

const useGetFiles = (
	ids: string[],
	options?: UseQueryOptions<{ file: Blob; fileId: string }, string[]>
) => {
	const allItemImagesQuery = useQueries({
		queries: ids.map((fileId) => ({
			queryKey: ['file', fileId],
			queryFn: async () => {
				const file = await getFile(fileId);
				return { file, fileId };
			},
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			...(options || {}),
		})),
	});

	const isFetching = allItemImagesQuery.some((query) => query.isFetching);

	useEffect(() => {
		const hasError = allItemImagesQuery?.some((query) => query.error);
		if (hasError) {
			displayAlertError(
				'An error occurred fetching the files, please try again'
			);
		}
	}, [allItemImagesQuery]);

	return { queries: allItemImagesQuery, isFetching };
};

export default useGetFiles;
