import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Form, Button, Row, Col, Alert } from 'react-bootstrap';
import { ApiService } from '../../../../lib/api/HttpService';
import { AsyncPaginate } from 'react-select-async-paginate';
import AsyncDropdownLoadingIndicator from '../../dropdowns/utils/AsyncDropdownLoadingIndicator';
import {
	getServiceFn,
	overrideQueryProperty,
} from '../../dropdowns/utils/AsyncDropdownFunctions';
import AsyncLocationsDropdown from '../../dropdowns/AsyncLocationsDropdown';
import {
	displayAlert,
	displayAlertLoader,
} from '../../../../utilities/Response';
import './InventoryItemForm.css';
import getAsyncReactSelectOptions, {
	IAsyncDropdownOptionsParams,
} from '../../dropdowns/utils/AsyncDropdownOptions';
import { EAlertTypes } from '../../../enums/alertTypes/alertTypes';
import { InventoryTypes } from '../../../enums/inventoryTypes/inventoryTypes';
import useGetCompany from '../../../../lib/api/hooks/useGetCompany';
import useGetCompanyLabel from 'legacy/lib/api/hooks/useGetCompanyLabel';

interface ISelectOption {
	label: string;
	value: string;
	name: string;
	id: number;
}

interface IFormFields {
	stockNo: ISelectOption;
	quantity: number;
	warehouse: ISelectOption;
	location: ISelectOption;
	useInventorySellPrice: number;
}

interface IResponseMessage {
	message?: string;
	type?: EAlertTypes;
}

interface IInventoryItemFormProps {
	closeModal: (response?: IResponseMessage) => void;
	locationQuery: string;
	projectId: string;
	type: InventoryTypes;
	itemId?: string;
}

const api = new ApiService();

const InventoryItemForm = ({
	closeModal,
	locationQuery = '',
	projectId,
	type = InventoryTypes.Item,
	itemId,
}: IInventoryItemFormProps) => {
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [isOverCommitted, setIsOverCommitted] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const overCommittedMesage = `By clicking 'Ok' you can try to create the ${type} regardless.`;
	const { data } = useGetCompany();
	const { data: companyLabel } = useGetCompanyLabel(data?.whshipto || '');

	const { register, handleSubmit, watch, control, setValue } =
		useForm<IFormFields>({
			defaultValues: {
				useInventorySellPrice: 1,
			},
		});

	const allValues = watch();

	useEffect(() => {
		const fetchDefaultWarehouse = async () => {
			try {
				if (companyLabel) {
					const defaultId = 0;

					setValue('warehouse', {
						label: companyLabel.label,
						value: companyLabel.value,
						name: companyLabel.name,
						id: defaultId,
					});
				}
			} catch (error: unknown) {
				const responseError = error as {
					response?: { data?: { userError?: string } };
				};
				displayAlert(
					EAlertTypes.Danger,
					responseError.response?.data?.userError || 'Unknown error',
					7000
				);
			}
		};
		fetchDefaultWarehouse();
	}, [setValue, companyLabel]);

	const onSubmit: SubmitHandler<IFormFields> = async (data) => {
		const params = {
			stockNo: data.stockNo?.value,
			whCode: data.warehouse?.value,
			newQty: data?.quantity,
		};

		setIsLoading(true);

		try {
			const { isOverCommitted, message } = await api.checkInventoryOnHand(
				params
			);
			if (isOverCommitted) {
				setErrorMessage(message);
				setIsOverCommitted(true);
			} else {
				await handleProceed();
				setErrorMessage(null);
			}
		} catch (error: unknown) {
			const message = 'Error checking inventory';
			closeModal({ message, type: EAlertTypes.Danger });
		} finally {
			setIsLoading(false);
		}
	};

	const quantityRegister = register('quantity');

	const getLoadOptions = (
		type: string
	): ((
		search: string,
		loadedOptions: any,
		additional: { page: number }
	) => Promise<{
		options: any;
		hasMore: boolean;
		additional?: { page: number };
	}>) => {
		let options;

		switch (type) {
			case 'inventory':
				options = {
					props: overrideQueryProperty('inventory', {
						urlQuery: 'inactive eq false&$orderby=stockno desc',
						totalPerPage: 10,
						page: 1,
					}),
					getDataFn: getServiceFn('inventory'),
					field: 'stockno',
					searchKeys: ['stockno', 'itemName'],
					valueKey: 'value',
					type: 'inventory',
				};
				break;
			case 'warehouses':
				options = {
					props: overrideQueryProperty('warehouses', {
						urlQuery: '?$filter=addresstype eq 1 and warehouse eq true',
						totalPerPage: 10,
						page: 1,
					}),
					getDataFn: getServiceFn('warehouses'),
					field: 'sortname',
					searchKeys: ['sortname', 'code'],
					valueKey: 'value',
					type: 'warehouses',
				};
				break;
			default:
				throw new Error(`Load options for ${type} are not available.`);
		}
		const { loadOptions } = getAsyncReactSelectOptions(
			options as IAsyncDropdownOptionsParams
		);

		return loadOptions;
	};

	const handleProceed = async () => {
		const payload = {
			stockNo: allValues.stockNo?.value,
			warehouseCode: allValues.warehouse?.value,
			locationCode: allValues.location?.value,
			projectId: projectId,
			useInventorySellPrice: Boolean(allValues.useInventorySellPrice),
			strGroupName: '',
			compQty: allValues?.quantity || 0,
			...(type === InventoryTypes.Component && {
				AddCompToExistingItemId: itemId,
			}),
		};

		setIsLoading(true);

		try {
			displayAlertLoader(`Creating ${type} from Inventory`);
			await api.createItemFromInventory(payload);
			const message = `Successfully created new ${type} from Inventory.`;
			closeModal({ message, type: EAlertTypes.Success });
		} catch (error: unknown) {
			displayAlertLoader(`Creating ${type} from Inventory`);
			if (typeof error === 'object' && error !== null && 'response' in error) {
				const responseError = error as {
					response?: { data?: { userError?: string } };
				};

				const message =
					responseError.response?.data?.userError || 'Unknown error';
				closeModal({ message, type: EAlertTypes.Danger });
			}
		} finally {
			setIsOverCommitted(false);
			setErrorMessage(null);
			setIsLoading(false);
		}
	};

	const handleCancel = () => {
		closeModal();
		setIsOverCommitted(false);
	};

	const clearError = () => {
		setErrorMessage(null);
		setIsOverCommitted(false);
	};

	return (
		<Form onSubmit={handleSubmit(onSubmit)}>
			<Row className="mb-3">
				<Col>
					<Form.Group controlId="formStockNo">
						<Form.Label>Stock No:</Form.Label>
						<Controller
							name="stockNo"
							control={control}
							render={({ field: { onChange, onBlur, value, ref } }) => (
								<div ref={ref}>
									<AsyncPaginate
										onChange={(value) => {
											onChange(value);
											clearError();
										}}
										onBlur={onBlur}
										value={value}
										debounceTimeout={800}
										placeholder="Select Stock No."
										loadOptions={
											getLoadOptions('inventory') as (
												search: string,
												loadedOptions: any,
												additional: { page: number } | undefined
											) => Promise<{
												options: any;
												hasMore: boolean;
												additional: { page: number };
											}>
										}
										components={{
											LoadingIndicator: AsyncDropdownLoadingIndicator,
										}}
										additional={{
											page: 1,
										}}
									/>
								</div>
							)}
						/>
					</Form.Group>
				</Col>
			</Row>

			<Row className="mb-3">
				<Col xs={4}>
					<Form.Group controlId="formQuantity">
						<Form.Label>Quantity:</Form.Label>
						<Form.Control
							type="decimal"
							onChange={(e) => {
								quantityRegister.onChange(e), clearError();
							}}
							onBlur={quantityRegister.onBlur}
							ref={quantityRegister.ref}
							name={quantityRegister.name}
							className="quantity-input"
						/>
					</Form.Group>
				</Col>
				<Col>
					<Form.Group controlId="formWarehouse">
						<Form.Label>Warehouse:</Form.Label>
						<Controller
							name="warehouse"
							control={control}
							render={({ field: { onChange, onBlur, value, ref } }) => (
								<div ref={ref}>
									<AsyncPaginate
										onChange={(value) => {
											onChange(value);
											clearError();
										}}
										onBlur={onBlur}
										value={value}
										debounceTimeout={800}
										placeholder="Select a Warehouse"
										loadOptions={
											getLoadOptions('warehouses') as (
												search: string,
												loadedOptions: any,
												additional: { page: number } | undefined
											) => Promise<{
												options: any;
												hasMore: boolean;
												additional: { page: number };
											}>
										}
										components={{
											LoadingIndicator: AsyncDropdownLoadingIndicator,
										}}
										additional={{
											page: 1,
										}}
									/>
								</div>
							)}
						/>
					</Form.Group>
				</Col>
			</Row>

			<Row className="mb-3">
				<Col>
					<Form.Group controlId="formLocation">
						<Form.Label>Location:</Form.Label>
						<Controller
							name="location"
							control={control}
							render={({ field: { onChange, onBlur, value } }) => (
								<AsyncLocationsDropdown
									onChange={onChange}
									onBlur={onBlur}
									value={value}
									urlQuery={locationQuery}
									className="react-select"
									placeholder="Please select"
									prependOptions={[{ label: 'All', value: '' }]}
								/>
							)}
						/>
					</Form.Group>
				</Col>
			</Row>

			<Row className="mb-3">
				<Col>
					<Form.Group>
						<Form.Label>Pricing:</Form.Label>
						<div key={`inline-radio`} className="mb-3">
							<div key={`inline-radio`} className="mb-3">
								<Form.Check
									inline
									label="Use inventory Sell Price"
									type="radio"
									id={`inline-radio-1`}
									onChange={() => setValue('useInventorySellPrice', 1)}
									checked={watch('useInventorySellPrice') === 1}
									name="useInventorySellPrice"
									value={1}
									className="me-4"
								/>
								<Form.Check
									inline
									label="Use Project Pricing Calculation"
									type="radio"
									id={`inline-radio-2`}
									onChange={() => setValue('useInventorySellPrice', 0)}
									checked={watch('useInventorySellPrice') === 0}
									name="useInventorySellPrice"
									value={0}
								/>
							</div>
						</div>
					</Form.Group>
				</Col>
			</Row>
			{!isOverCommitted && (
				<Row className="w-100">
					<Col className="d-flex" lg={{ span: 10, offset: 1 }}>
						<Button
							variant="primary"
							className="w-100 m-2"
							type="button"
							disabled={isLoading}
							onClick={handleSubmit(onSubmit)}
						>
							Ok
						</Button>

						<Button
							type="button"
							variant="trans-light border-secondary-ash"
							onClick={handleCancel}
							className="w-100 m-2"
							disabled={isLoading}
						>
							Cancel
						</Button>
					</Col>
				</Row>
			)}
			<div>
				{errorMessage && (
					<Row className="mb-3">
						<Alert variant="danger">
							{errorMessage} {overCommittedMesage}
							{isOverCommitted && (
								<Col className="d-flex" lg={{ span: 10, offset: 1 }}>
									<Button
										type="button"
										variant="primary"
										onClick={handleProceed}
										className="w-100 m-2"
										disabled={isLoading}
									>
										Ok
									</Button>
									<Button
										type="button"
										variant="secondary"
										onClick={handleCancel}
										className="w-100 m-2"
										disabled={isLoading}
									>
										Cancel
									</Button>
								</Col>
							)}
						</Alert>
					</Row>
				)}
			</div>
		</Form>
	);
};

InventoryItemForm.displayName = 'InventoryItemForm';

export default InventoryItemForm;
