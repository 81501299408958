import { TTypeOption } from 'legacy/app/components/dropdowns/types/TTypeOption';
import UnitsOfMeasureDropdown from 'legacy/app/components/dropdowns/UnitsOfMeasuresDropdown';
import WysiwygEditor from 'legacy/templates/ui-kit/WysiwygEditor';
import { FormControl } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import Budget from './Budget';
import Deposit from './Deposit';
import ImageUploader from './Image';
import LocationSelect from './LocationSelect';
import PurchaseComponentsTable from './PurchaseComponentsTable';
import QuantityInput from './QuantityInput';
import SalesCategoriesSelect from './SalesCategoriesSelect';
import Label from 'legacy/app/components/label/Label';
import { ItemFormValues } from '../types/FormValues';
import { Item } from 'legacy/lib/api/types/Item';
import { WhatChanged } from 'legacy/lib/api/types/WhatChangedItems';
import { useFeature } from 'use-feature';
import { FeatureFlags } from 'legacy/app/enums/featureFlags/featureFlags';
import ImageGallery from 'legacy/app/components/ImageGallery/ImageGallery';
import LocationsQuickAddModal from 'legacy/app/components/modal/LocationsQuickAddModal';
import { useEffect, useRef, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { TTypeLocation } from 'legacy/lib/api/types/Location';
import { useParams } from 'react-router-dom';

type TItemInfoProps = {
	projectCode: string;
	hasRecalculatedOnLoad: boolean;
	shouldRecalculateOnLoad: boolean;
	submitFormFromOutside: () => Promise<void>;
	description: string;
	disableButtons: boolean;
	isRecalculating: boolean;
	itemToEdit?: Item;
	handleRecalculate: (whatPropertyChanged: `${WhatChanged}`) => void;
};

const Info = ({
	itemToEdit,
	description,
	projectCode,
	isRecalculating,
	shouldRecalculateOnLoad,
	hasRecalculatedOnLoad,
	handleRecalculate,
	disableButtons,
	submitFormFromOutside,
}: TItemInfoProps) => {
	const [createOptionLocationName, setCreateOptionLocationName] = useState('');
	const methods = useFormContext<ItemFormValues>();
	const nameInput = methods.register('itemName');
	const showImageGallery = useFeature(FeatureFlags.MultipleImages);
	const queryClient = useQueryClient();
	const { id: projectId } = useParams();

	const handleRecalculateRef = useRef(handleRecalculate);

	useEffect(() => {
		if (itemToEdit && !hasRecalculatedOnLoad && shouldRecalculateOnLoad) {
			handleRecalculateRef.current(WhatChanged.RECALC_FROM_COMPS_AND_STATUS);
		}
	}, [itemToEdit, hasRecalculatedOnLoad, shouldRecalculateOnLoad]);

	return (
		<>
			<div className="xl:tw-flex tw-!gap-4 tw-p-10 tw-flex-wrap">
				{showImageGallery ? (
					<ImageGallery itemToEdit={itemToEdit} />
				) : (
					<ImageUploader itemToEdit={itemToEdit} />
				)}
				<div className="tw-flex tw-flex-col xl:tw-grid tw-items-center lg:tw-flex-1 tw-gap-x-6 tw-gap-y-4 tw-grid-cols-3">
					<Label
						label="Name"
						className="tw-mt-[1rem] xl:tw-mt-[0] tw-text-right"
					/>
					<FormControl
						type="text"
						className="tw-h-8 tw-col-span-2"
						placeholder="Name"
						id="Name"
						maxLength={45}
						name={nameInput.name}
						onChange={nameInput.onChange}
						ref={nameInput.ref}
					/>
					<Label
						label="Description"
						className="tw-pt-4 tw-self-baseline tw-text-right"
					/>
					<Controller
						control={methods.control}
						name="description"
						render={({ field }) => (
							<div className="tw-col-span-2 tw-w-full">
								<WysiwygEditor
									key={description}
									keepEmptySpaces
									triggerChangeOnLoad
									onChange={(e: {
										target: {
											html: string;
											value: string;
										};
									}) => field.onChange(e.target)}
								>
									{/* We are using description as key and props to trigger a state change on the Editor because the
                    Editor internals are all messed up
                     */}
									{description}
								</WysiwygEditor>
							</div>
						)}
					/>
					<LocationSelect
						onCreateOption={(value) => setCreateOptionLocationName(value)}
						disabled={isRecalculating}
						handleRecalculate={handleRecalculate}
					/>
					<Label label="Quantity" className="tw-text-right" />
					<QuantityInput
						disabled={isRecalculating}
						handleRecalculate={handleRecalculate}
					/>
					<div className="tw-flex tw-flex-col xl:tw-flex-row tw-justify-center xl:tw-justify-start tw-w-full lg:tw-col-span-1 tw-items-center !tw-gap-4 ">
						<Label label="Units of Measure" />
						<Controller
							control={methods.control}
							name="unitmeasure"
							render={({ field }) => (
								<div className="tw-flex-1 tw- lg:tw-min-w-28 tw-w-full">
									<UnitsOfMeasureDropdown
										inputId="Units of Measure"
										value={field.value as TTypeOption}
										onChange={(val) => field.onChange(val)}
									/>
								</div>
							)}
						/>
					</div>
					<Budget
						disabled={isRecalculating}
						handleRecalculate={handleRecalculate}
					/>
					<SalesCategoriesSelect
						disabled={isRecalculating}
						handleRecalculate={handleRecalculate}
					/>
				</div>
			</div>
			<Deposit
				itemToEdit={itemToEdit}
				disableDepositSelect={isRecalculating}
				handleRecalculate={handleRecalculate}
			/>
			<PurchaseComponentsTable
				disableButtons={disableButtons}
				submitMethods={submitFormFromOutside}
			/>
			{createOptionLocationName && (
				<LocationsQuickAddModal
					projCode={projectCode}
					locAddName={createOptionLocationName}
					hideModal={() => setCreateOptionLocationName('')}
					handleSuccess={(res: TTypeLocation) => {
						methods.setValue('loc', {
							value: res.loc,
							label: '',
						});
						// Need to wait one second because the location summaries endpoint takes a bit to update
						setTimeout(async () => {
							await queryClient.resetQueries([
								'dropdowns',
								'locationsSummaries',
								projectId,
							]);
							setCreateOptionLocationName('');
							handleRecalculate(WhatChanged.LOCATION);
						}, 1000);
					}}
				/>
			)}
		</>
	);
};
Info.displayName = 'ItemsInfoTab';
export default Info;
