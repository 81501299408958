import React from 'react';
import { Link, NavLink, generatePath } from 'react-router-dom';
import {
	Container,
	Breadcrumb,
	Button,
	Table,
	Form,
	Col,
	Row,
} from 'react-bootstrap';
import ThreadComponent from '../../ThreadComponent';
import { getRouteWithParam, WithRouter } from '../../../../helpers/Router';
import { HeaderLight } from '../../../components/Header';
import URI from '../../../../defaults/RoutesDefault';
import { getSaveButtonName } from '../../../../utilities/String';
import ListFilter from '../../../components/ListFilter';
import DatePicker from 'react-datepicker';
import { FormEvent, setDraft } from '../../../../utilities/FormEvent';
import { ApiService } from '../../../../lib/api/HttpService';
import noItems from '../../../../assets/images/icons/item.svg';
import { FooterFormAction } from '../../../components/Form';
import AddComponentModal from '../../modal/AddComponentModal';
import { isFiltered } from '../../../../helpers/Util';
import { getCheckAllClass } from '../../../../utilities/ModuleHelper';
import FilterSort from '../../../../utilities/modules/FilterSort';
import { addDomClass, hasClass, removeDomClass } from '../../../../helpers/DOM';
import { debounce, isEmpty, sum, toLower } from 'lodash';
import { SearchExpressionButton } from '../../uikit/SearchExpressionButton';
import {
	getComponentName,
	setComponents,
} from '../../../../utilities/ComponentTypes';
import { truncateCharCount } from '../../../../helpers/String';
import { tableSearch } from '../../../../utilities/modules/TableSearch';
import {
	sortLocalData,
	tableSortingEnableSort,
} from '../../../../utilities/modules/TableSorting';
import {
	displayAlertError,
	displayAlertLoader,
	displayAlertSuccess,
	getErrorMessage,
} from '../../../../utilities/Response';
import {
	getLocalStorage,
	setLocalStorage,
} from '../../../../utilities/LocalStorage';
import dayjs from 'dayjs';
import ProjectVendorOrdersAddPurchaseOrderGeneratedPOModal from './ProjectVendorOrdersAddPurchaseOrderGeneratedPOModal';
import MSG from '../../../../defaults/Message';
import { pF } from '../../../../utilities/Number';
import { showLoading } from '../../../../helpers/Loading';
import { ErrorObjects } from '../../../../defaults/Error';
import { formatDateTime } from '../../../../helpers/Date';
import loadItemImages, { renderItemImage } from '../../../../utilities/Images';
import SecureBootstrapButton from '../../../../app/components/security/SecureBootstrapButton';
import { SECURITY_ATTRIBUTE_TYPES } from '../../../../app/context/security';

class ProjectVendorOrdersAddPurchaseOrder extends ThreadComponent {
	constructor(props) {
		super(props);

		this.filterKey = 'project_vporder_add_item_' + this.props.params.id;
		this.fs = new FilterSort(this.filterKey);

		this.state = {
			data: {},
			project: {},
			isLoading: false,
			dataIsLoaded: false,
			imagesLoaded: false,
			imageData: {},
			changeOrder: false,
			showTableSearch: false,
			showComponentModal: false,
			showPOModal: false,
			selectedComponents: [],
			filteredComponents: [],
			selectedItems: [],
			checks: [],
			searchProperties: {},
			sortProperty: 'refNum desc',
		};

		this.api = new ApiService();
		this.formEvent = new FormEvent(`purchase_order_edit`);

		this.projId = this.props.params.id;
		this.orderId = this.props.params.orderId;
		this.isEditing = this.orderId != null;
		this.searchHandler = debounce(this.handleDoSearch.bind(this, 1), 200);
		this.setComponentProp();
		setComponents();
	}

	componentInit() {
		this.setFormAction(true);
		this.setTitle(
			this.props.params.orderId
				? `Edit Purchase Order [${this.props.params.orderId}]`
				: 'Add Purchase Order'
		);
	}

	setComponentProp() {
		this.saveSuccessMessage = this.isEditing
			? MSG.success.msg
			: MSG.success.create.ProjectPO;
		this.saveLoadingMessage = this.isEditing
			? MSG.loading.update.msg
			: MSG.loading.create.ProjectPO;
	}

	async componentDidMount() {
		try {
			await this.fetchData();

			const fromQuickCreate = new URLSearchParams(window.location.search).get(
				'isQuickCreate'
			);

			if (fromQuickCreate) {
				setTimeout(async () => {
					const preData = getLocalStorage('preData', true);

					if (preData !== null && preData.length) {
						const ids = preData.join(',');
						const components = await this.api.getComponents(
							`?$filter=itemid in (${ids}) AND projectId eq ${this.projId}&$orderby=comp asc`
						);

						this.setState({
							selectedComponents: components,
						});
					}
				}, 2000);
			}
		} catch (error) {
			this.setIsFound(false).setError(error, true);
			this.setState({
				__updateAt: new Date(),
			});
		}
		this.setIsLoaded(true);
	}

	componentWillUnmount() {}

	async fetchData() {
		const projectId = this.projId;
		const orderId = this.orderId;

		let projectFilter = `?$filter=id eq ${projectId}`;
		const getProject = await this.api.getProjects(projectFilter);
		const company1 = await this.api.get('company', '');

		if (isEmpty(getProject)) {
			throw ErrorObjects.Notfound;
		}

		let orderComponents = [];
		let data = {
			curProj: getProject[0].proj,
			orderDt: dayjs(new Date()).format('YYYY-MM-DD'),
			itemCompNoArray: [],
			replacePONum: '',
			revdt: null,
			showPictures: true,
			isWO: false,
		};

		if (typeof orderId !== 'undefined') {
			let filter = `?$filter=proj eq '${getProject[0].proj}' and ponum eq '${orderId}'`;
			let purchaseOrders = await this.api.getProjectPurchaseOrders(filter);
			let purchaseOrder = purchaseOrders[0];

			orderComponents =
				purchaseOrder.potype === 0
					? await this.api.getComponents(
							`?$filter=proj eq '${getProject[0].proj}' and latestponum eq '${orderId}' &$orderby=completeVendor asc, refNum asc `
					  )
					: await this.api.getComponents(
							`?$filter=proj eq '${getProject[0].proj}' and latestwonum eq '${orderId}' &$orderby=completeVendor asc, refNum asc`
					  );

			data = {
				...data,
				...purchaseOrder,
				poAmount: isEmpty(orderComponents)
					? 0
					: sum(orderComponents.map((comp) => parseFloat(comp.estcost))),
				itemCompNoArray: orderComponents.map(
					(comp) => `${comp.item}/${comp.comp}`
				),
				revdt:
					purchaseOrder.revdt ??
					new Date().toLocaleString('en-US', {
						year: 'numeric',
						month: '2-digit',
						day: 'numeric',
					}),
			};
		}

		this.setState(
			{
				data,
				selectedItems: orderComponents,
				selectedComponents: orderComponents,
				project: getProject[0],
				changeOrder: company1.pochangeorder,
				dataIsLoaded: true,
			},
			() => {
				this.enableSortTable();
				this.loadImages(orderComponents);
			}
		);
	}

	async loadImages(data) {
		loadItemImages({
			data,
			property: 'primaryImageId',
			callback: (imageData) => {
				this.setState({
					imagesLoaded: true,
					imageData,
				});
			},
		});
	}

	getDateValue(key) {
		return this.state.data !== undefined &&
			this.state.data[key] !== null &&
			this.state.data[key] !== undefined
			? new Date(this.state.data[key])
			: new Date();
	}

	showComponentModal = (e) => {
		this.setState({
			showComponentModal: true,
		});
	};

	hideComponentModal = (e) => {
		this.setState({
			showComponentModal: false,
		});
	};

	handleShowTableSearch = (e) => {
		this.setState({
			showTableSearch: !this.state.showTableSearch,
		});
	};

	handleSearch = (e) => {
		const key = e.target.attributes['data-field'].value;
		const value = e.target.value;
		const type = e.target.attributes['data-type']?.value ?? 'string';
		const exp = e.target.attributes['placeholder']?.value ?? '>=';
		const custom = Boolean(e.target.attributes['data-custom']?.value);

		this.setState(
			{
				searchProperties: {
					...this.state.searchProperties,
					[key]: { value, type, exp, custom },
				},
			},
			this.searchHandler
		);
	};

	handleDoSearch = () =>
		tableSearch({
			data: this.state.selectedComponents,
			searchProps: this.state.searchProperties,
			callback: async (data) => {
				this.setState({
					filteredComponents: data,
				});
			},
			customFilter: (item, key) => {
				if (key === 'comptype') {
					return getComponentName(item[key]);
				}
				return item[key];
			},
		});

	handleDateChange = (name, date) => {
		let { data } = this.state;
		data[name] = date;
		this.setState({
			dataIsLoaded: true,
			data,
		});

		this.formEvent.handleDateChange('MM/DD/YYYY', name, formatDateTime(date));
	};

	handleChangeOrder = (e) => {
		this.setState({
			changeOrder: e.target.checked,
		});
	};

	handleAddItem = (selected) => {
		this.setState(
			{
				showComponentModal: false,
				selectedComponents: selected,
			},
			() => {
				this.enableSortTable();
			}
		);
	};

	handleSelectAllChecks = (e) => {
		const isChecked = e.target.checked;
		let checks = [];
		let selected = [];
		if (isChecked) {
			this.state.selectedComponents.map((item) => {
				checks.push(item.id);
				selected.push(item);
			});
		}

		this.setState({
			checks: checks,
			selectedItems: selected,
		});
	};

	handleSave = async (e) => {
		this.setIsLoading(true);
		displayAlertLoader(this.saveLoadingMessage);

		const { data, selectedComponents } = this.state;

		let purchaseOrder = data;
		const items = selectedComponents.map((comp) => `${comp.item}/${comp.comp}`);

		purchaseOrder.itemCompNoArray = items;

		if (this.isEditing) {
			purchaseOrder.replacePONum = this.props.params.orderId;
			purchaseOrder.revdt = this.state.changeOrder ? purchaseOrder.revdt : null;
		}

		this.api
			.postJson('temporarypurchaseorders', purchaseOrder)
			.then((res) => {
				const preData = getLocalStorage('preData');
				if (preData !== null) {
					window.localStorage.removeItem('preData');
				}

				this.setIsLoading(false);
				displayAlertSuccess(this.saveSuccessMessage);
				this.setState({
					showPOModal: true,
				});
			})
			.catch((err) => {
				displayAlertError(getErrorMessage(err));
				this.setIsLoading(false);
			});
	};

	setIsLoading(state) {
		this.setState({
			isLoading: state,
		});
	}

	handleRemoveItem = (e) => {
		const checks = this.state.checks;
		let components = this.state.selectedComponents;
		let newComponents = [];

		components.map((item, i) => {
			if (!checks.includes(item.id)) {
				newComponents.push(item);
			}
		});

		this.setState({
			selectedComponents: newComponents,
			selectedItems: [],
			checks: [],
		});
	};

	handleDeletePOObject = () => {};

	handleCheck = (item) => {
		const checks = this.state.checks;
		const index = checks.findIndex((_id) => _id === item.id);
		let selected = this.state.selectedItems;

		if (index > -1) {
			checks.splice(index, 1);
		} else {
			checks.push(item.id);
		}

		this.setState({
			checks: checks,
			selectedItems: selected,
		});
	};

	sortClass(name) {
		return `sort ${this.fs.isActiveSort(name)}`;
	}

	formatDesc(item) {
		const itemName = item.itemName || '';
		const itemDesc = item.desc || '';

		let result = '';

		if (itemName && itemDesc) {
			const truncatedDesc = truncateCharCount(itemDesc, 100);
			result = truncatedDesc.includes(itemName)
				? truncatedDesc
				: `${itemName} ${truncatedDesc}`;
		} else {
			result = itemName || itemDesc || '';
		}

		return result;
	}

	enableSortTable = () =>
		tableSortingEnableSort({
			data: this.state.selectedComponents,
			classRef: this,
			targetTable: '.a-table-heading.main',
			dataKey: 'po_selected_main',
			callback: (data, sort) => {
				tableSearch({
					data,
					searchProps: this.state.searchProperties,
					callback: async (data) => {
						this.setState({
							filteredComponents: data,
						});
					},
				});

				this.setState({
					sortProperty: sort,
				});

				// Save sortProperty to localStorage
				this.fs.setSort(sort);
			},
			pattern: (key) => [
				(d) => {
					if (typeof d[key] === 'string') {
						return toLower(d[key]);
					}

					if (key === 'comptype') {
						return toLower(getComponentName(d[key]));
					}

					return d[key] ?? '';
				},
			],
		});

	setImageData(imageData) {
		this.setState({
			imagesLoaded: true,
			imageData: {
				...this.state.imageData,
				...imageData,
			},
		});
	}

	renderHeader() {
		return (
			<HeaderLight>
				<HeaderLight.Breadcumbs>
					<NavLink
						to={getRouteWithParam(URI.project.viewPage, {
							id: this.projId,
							page: 'vendor-orders',
						})}
						className="text-primary active d-flex align-items-center text-decoration-none fw-bold"
					>
						<i className="ri-arrow-left-s-line"></i> Back
					</NavLink>
					<Breadcrumb className="ms-4">
						<Breadcrumb.Item linkProps={{ to: URI.project.base }} linkAs={Link}>
							Projects
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{
								to: getRouteWithParam(URI.project.viewPage, {
									id: this.projId,
									page: 'items',
								}),
							}}
							linkAs={Link}
						>
							{this.state.project
								? `${this.state.project.projn} [${this.state.project.proj}]`
								: ''}
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{
								to: getRouteWithParam(URI.project.viewPage, {
									id: this.projId,
									page: 'vendor-orders',
								}),
							}}
							linkAs={Link}
						>
							Purchase Orders
						</Breadcrumb.Item>
						<Breadcrumb.Item>
							{this.isEditing ? this.props.params.orderId : this.title}
						</Breadcrumb.Item>
					</Breadcrumb>
				</HeaderLight.Breadcumbs>
				<HeaderLight.Content actions={true}>
					<HeaderLight.Title>{this.title}</HeaderLight.Title>
					<div>
						<Button
							as={Link}
							variant="ivory"
							name="cancel"
							to={getRouteWithParam(URI.project.viewPage, {
								id: this.projId,
								page: 'vendor-orders',
							})}
							className="mx-3"
						>
							Cancel
						</Button>
						<SecureBootstrapButton
							attributeNo={18}
							attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
							variant="primary"
							name="save"
							onClick={this.handleSave.bind(this)}
							disabled={this.state.isLoading}
						>
							{getSaveButtonName(this.state.isLoading, 'Save')}
						</SecureBootstrapButton>
					</div>
				</HeaderLight.Content>
			</HeaderLight>
		);
	}

	renderListFilter() {
		return (
			<>
				<ListFilter breakpoint={1280}>
					<ListFilter.Fields md={12} lg={8} xl={8} xxl={7} className="width-33">
						<ListFilter.Field>
							<Form.Label className="text-end">
								<strong>Date</strong>
							</Form.Label>
							<div className="react-select-header">
								<DatePicker
									selected={this.getDateValue('orderdt')}
									minDate={this.state.minDate}
									onChange={this.handleDateChange.bind(this, 'orderdt')}
									showMonthDropdown="true"
									showYearDropdown="true"
									className="form-control form-control-sm"
									placeholderText="11/17/2020"
								/>
							</div>
						</ListFilter.Field>
						<ListFilter.Field>
							{this.isEditing && (
								<Form.Check
									inline
									label="Change Order"
									name="changeOrder"
									type="checkbox"
									id={`group-check-show-all`}
									className="ms-3"
									checked={this.state.changeOrder}
									onClick={this.handleChangeOrder}
								/>
							)}
						</ListFilter.Field>
						{this.isEditing && this.state.changeOrder && (
							<ListFilter.Field>
								<Form.Label className="text-end">
									<strong>
										Revision
										<br />
										Date
									</strong>
								</Form.Label>
								<div className="react-select-header">
									<DatePicker
										selected={this.getDateValue('revdt')}
										onChange={this.handleDateChange.bind(this, 'revdt')}
										showMonthDropdown="true"
										showYearDropdown="true"
										className="form-control form-control-sm"
										placeholderText="11/17/2020"
									/>
								</div>
							</ListFilter.Field>
						)}
					</ListFilter.Fields>
					<ListFilter.Actions md={12} lg={4} xl={4} xxl={5}>
						<ListFilter.Action>
							{this.state.selectedComponents.length > 0 && (
								<>
									<Button
										as={Link}
										to="#"
										variant="ivory"
										size="sm"
										className={`btn-icon btn-action ${
											this.state.showTableSearch
												? 'bg-primary-ash text-white'
												: ''
										}`}
										onClick={this.handleShowTableSearch}
									>
										<i className="ri-search-line"></i> Search
									</Button>
									<Button
										variant="ivory"
										size="sm"
										className="btn-icon ms-3"
										disabled={!this.state.checks.length}
										onClick={this.handleRemoveItem}
									>
										Remove Component
									</Button>
								</>
							)}
							<Button
								variant="primary"
								size="sm"
								className="btn-icon ms-3"
								onClick={this.showComponentModal}
							>
								Add Component
							</Button>
						</ListFilter.Action>
					</ListFilter.Actions>
				</ListFilter>
				<ListFilter.Spacer />
			</>
		);
	}

	renderContent() {
		return (
			<>
				{this.state.selectedComponents.length > 0 ||
				isFiltered(this.state.searchProperties) ||
				this.isEditing ? (
					<div className="table-gradient mb-5">
						<Table striped responsive className="a-table">
							<thead>
								<tr key="0" className="a-table-heading main">
									<th className="ps-4">
										<Form.Check
											inline
											label=""
											type="checkbox"
											defaultChecked={this.state.checks.length > 0}
											className={getCheckAllClass(
												this.state.checks.length,
												this.state.checks
											)}
											onClick={this.handleSelectAllChecks}
										/>
									</th>
									<th>Image</th>
									<th className="mw-100px">
										<span
											className={this.sortClass('refNum')}
											data-field="refNum"
										>
											Ref No.
										</span>
									</th>
									<th className="mw-100px">
										<span
											className={this.sortClass('vendorName')}
											data-field="vendorName"
										>
											{this.props.orderType && this.props.orderType == 'wo'
												? 'Workroom'
												: 'Vendor'}
										</span>
									</th>
									<th className="mw-180px">
										<span className={this.sortClass('desc')} data-field="desc">
											Description
										</span>
									</th>
									<th className="mw-110px">
										<span
											className={this.sortClass('quantity')}
											data-field="quantity"
										>
											Quantity
										</span>
									</th>
									<th className="mw-140px">
										<span
											className={this.sortClass('estcost')}
											data-field="estcost"
										>
											Estimated Cost
										</span>
									</th>
									<th className="mw-100px">
										<span
											className={this.sortClass('comptype')}
											data-field="comptype"
										>
											Type
										</span>
									</th>
									<th>
										<span
											className={this.sortClass('completeLocation')}
											data-field="completeLocation"
										>
											Location
										</span>
									</th>
									<th className="mw-100px">
										<span
											className={this.sortClass('completeShipTo')}
											data-field="completeShipTo"
										>
											Ship To
										</span>
									</th>
									<th>
										<span
											className={this.sortClass('catalog')}
											data-field="catalog"
										>
											Catalog No.
										</span>
									</th>
									<th>
										<span
											className={this.sortClass('latestProposalNo')}
											data-field="latestProposalNo"
										>
											Last Proposal No
										</span>
									</th>
								</tr>
								<tr
									className={`a-table-search-fields selected ${
										this.state.showTableSearch ? '' : 'd-none'
									}`}
								>
									<th></th>
									<th></th>
									<th>
										<Form.Control
											data-type="text"
											data-field="refNum"
											onChange={this.handleSearch}
										/>
									</th>
									<th>
										<Form.Control
											data-type="text"
											data-field="completeVendor"
											onChange={this.handleSearch}
										/>
									</th>
									<th>
										<Form.Control
											data-type="text"
											data-field="desc"
											onChange={this.handleSearch}
										/>
									</th>
									<th className="position-relative">
										<Form.Control
											type="number"
											data-field="quantity"
											placeholder={this.state.expQty || '>='}
											data-type="number"
											onChange={this.handleSearch}
										/>
										<SearchExpressionButton
											operator={this.state.expQty}
											callback={(expQty) =>
												this.setState({
													expQty,
												})
											}
										/>
									</th>
									<th className="position-relative">
										<Form.Control
											type="number"
											data-field="estcost"
											placeholder={this.state.expEstCost || '>='}
											data-type="number"
											onChange={this.handleSearch}
										/>
										<SearchExpressionButton
											operator={this.state.expEstCost}
											callback={(expEstCost) =>
												this.setState({
													expEstCost,
												})
											}
										/>
									</th>
									<th>
										<Form.Control
											data-type="text"
											data-field="comptype"
											data-custom="comptype"
											onChange={this.handleSearch}
										/>
									</th>
									<th>
										<Form.Control
											data-type="text"
											data-field="completeLocation"
											onChange={this.handleSearch}
										/>
									</th>
									<th>
										<Form.Control
											data-type="text"
											data-field="completeShipTo"
											onChange={this.handleSearch}
										/>
									</th>
									<th>
										<Form.Control
											data-type="text"
											data-field="catalog"
											onChange={this.handleSearch}
										/>
									</th>
									<th>
										<Form.Control
											data-type="text"
											data-field="latestProposalNo"
											onChange={this.handleSearch}
										/>
									</th>
								</tr>
							</thead>
							<tbody>
								{!this.state.dataIsLoaded
									? showLoading()
									: (this.state.filteredComponents.length > 0
											? this.state.filteredComponents
											: this.state.selectedComponents
									  ).map((item, i) => (
											<tr
												key={i}
												className={
													this.state.checks.includes(item.id)
														? `active tr-loading-1`
														: 'tr-loading-1'
												}
											>
												<td className="ps-4">
													<Form.Check
														inline
														label=""
														type="checkbox"
														className="chk-component-items"
														checked={this.state.checks.includes(item.id)}
														onChange={() => {
															this.handleCheck(item);
														}}
													/>
												</td>
												<td className="td-1">
													{renderItemImage({
														item,
														imagesLoaded: this.state.imagesLoaded,
														imageData: this.state.imageData,
														property: 'primaryImageId',
														altProperty: 'itemName',
													})}
												</td>
												<td>{item.refNum}</td>
												<td>{item.vendorName}</td>
												<td>{this.formatDesc(item)}</td>
												<td>{`${item.quantity}  ${
													item?.unitmeasure || ''
												}`}</td>
												<td>{item.estcost?.toFixed(2)}</td>
												<td>{getComponentName(item.comptype)}</td>
												<td>{item.completeLocation}</td>
												<td>{item.completeShipTo}</td>
												<td>{item.catalog}</td>
												<td>{item.latestProposalNo}</td>
											</tr>
									  ))}
							</tbody>
						</Table>
					</div>
				) : (
					<div className="row justify-content-center text-center py-5">
						<div className="col-md-3">
							<img src={noItems} className="mw-100 mb-4" alt="" />
							<h6>Add Components</h6>
							<p>
								Use the "Add Components" button to begin to associate components
								with this purchase order.
							</p>
							<Button
								as={Link}
								onClick={this.showComponentModal}
								variant="primary"
								size="md"
								className="btn-icon mt-4"
							>
								<i className="ri-add-line ri-lg"></i> Add Components
							</Button>
						</div>
					</div>
				)}
			</>
		);
	}

	render() {
		return this.renderView(
			<>
				{this.renderHeader()}
				<div className="content-padding min-height mh-270">
					<Container fluid>
						{this.renderListFilter()}
						{this.renderContent()}
					</Container>
				</div>
				<FooterFormAction>
					<Row className="w-100">
						<Col lg={4} className="text-center mb-3"></Col>
						<Col lg={8} className="align-right mb-3">
							<div className="d-flex justify-content-end">
								<Button
									as={Link}
									to={getRouteWithParam(URI.project.viewPage, {
										id: this.projId,
										page: 'vendor-orders',
									})}
									variant="trans-light border-secondary-ash"
									size="lg"
									className="mx-3"
								>
									Cancel
								</Button>
								<SecureBootstrapButton
									attributeNo={18}
									attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
									variant="primary"
									size="lg"
									onClick={this.handleSave}
									disabled={this.state.isLoading}
								>
									Save
								</SecureBootstrapButton>
							</div>
						</Col>
					</Row>
				</FooterFormAction>

				<AddComponentModal
					show={this.state.showComponentModal}
					hideModal={this.hideComponentModal}
					filterKey={this.filterKey}
					defaultSortField={'refNum'}
					project={this.state.project}
					orderId={this.orderId}
					handleAddItem={this.handleAddItem}
					selected={this.state.selectedComponents}
					setImageData={this.setImageData.bind(this)}
				/>
				{this.state.showPOModal && (
					<ProjectVendorOrdersAddPurchaseOrderGeneratedPOModal
						show={this.state.showPOModal}
						hideModal={() =>
							this.setState((prev) => (prev.showPOModal = false))
						}
					/>
				)}
			</>
		);
	}
}

export default WithRouter(ProjectVendorOrdersAddPurchaseOrder);
