export enum FeatureFlags {
	FileAttachments = 'FILE_ATTACHMENTS',
	RAINFOREST = 'RAINFOREST',
	AddInventoryItem = 'ADD_INVENTORY_ITEM',
	AddInventoryItemComponent = 'ADD_INVENTORY_ITEM_COMPONENT',
	CheckbookReconcile = 'CHECKBOOK_RECONCILE',
	DashboardHome = 'DASHBOARD_HOME',
	ProjectProposalStatus = 'PROJECT_PROPOSAL_STATUS',
	SpecificationTemplates = 'SPECIFICATION_TEMPLATES',
	DashboardCashflow = 'DASHBOARD_CASHFLOW',
	ProjectSchedule = 'PROJECT_SCHEDULE',
	Reporting = 'REPORTING',
	MultipleImages = 'MULTIPLE_IMAGES',
	ReconcileCC = 'CC_RECON',
}
