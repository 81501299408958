import React, { Fragment } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import ImageDragAndDrop from '../ImageDragAndDrop/ImageDragAndDrop';

interface ISortableImageDragAndDropProps {
	id: string;
	image?: File | Blob | null;
	handleFile: (file: File) => void;
	isSelected: boolean;
	isPlaceholder?: boolean;
	handleDelete: (index: number) => void;
}

const SortableImageDragAndDrop = ({
	id,
	image,
	handleFile,
	isSelected = false,
	isPlaceholder = false,
	handleDelete,
}: ISortableImageDragAndDropProps) => {
	const { attributes, listeners, setNodeRef, transform, transition } =
		useSortable({ id });
	const style = {
		transform: CSS.Transform.toString(transform),
		transition: transition || 'transform 200ms ease',
	};

	return (
		<div
			ref={setNodeRef}
			style={style}
			className={`tw-relative ${
				image ? 'tw-block' : 'tw-flex tw-items-center tw-justify-center'
			} tw-rounded-lg tw-border tw-p-2 tw-relative tw-h-full ${
				isSelected && 'tw-border-slate-600'
			} `}
		>
			{isPlaceholder ? (
				<div className="tw-w-full tw-h-full">
					<div
						key={`placeholder-item-${id}`}
						className="tw-w-full tw-h-full tw-rounded-lg tw-border tw-border-dashed"
					/>
				</div>
			) : (
				<Fragment>
					<div
						className="tw-absolute tw-inset-0 tw-border-1 tw-cursor-move"
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...attributes}
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...listeners}
					/>
					<ImageDragAndDrop
						image={image}
						title={'Add Photo'}
						subtitle={'or Drag and Drop'}
						iconClass="tw-text-lg tw-font-normal"
						isThumbnail={true}
						handleFile={handleFile}
						handleDelete={handleDelete}
						imageIndex={Number(id)}
					/>
				</Fragment>
			)}
		</div>
	);
};

SortableImageDragAndDrop.displayName = 'SortableImageDragAndDrop';

export default SortableImageDragAndDrop;
